import {BaseApp, ContextType, getProvisioningId} from "./BaseApp";
import {
  DatabaseReference,
  DataSnapshot,
  get,
  getDatabase,
  ListenOptions,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  ref,
  remove,
  set
} from "@firebase/database";

export enum SystemProvisioningIds {
  MAIN = "__main__",
  INHOUSE = "__inhouse__",
}

// These are excluded from provisioning id prefix.
export const GLOBAL_PATHS = [
  "users",
  "provisionings",
  "sync",
];

export enum SystemKeys {
  REL = "__rel__",
}

export class Rel {

  static oneToOnePathFor(name: string, id1: string): string {
    return "/" + SystemKeys.REL + "/" + name + "/" + id1;
  }

  static oneToManyPathFor(name: string, id1: string, id2: string): string {
    return "/" + SystemKeys.REL + "/" + name + "/" + id1 + "/" + id2;
  }

  constructor(readonly name: string, readonly id1: string, readonly id2: string, readonly multiple?: boolean) {
  }

  path(): string {
    if (this.multiple) {
      return Rel.oneToManyPathFor(this.name, this.id1, this.id2);
    }
    return Rel.oneToOnePathFor(this.name, this.id1);
  }
}

export class ProvisioningContext {

  static readonly DEFAULT = new ProvisioningContext();
  static readonly MAIN = new ProvisioningContext(SystemProvisioningIds.MAIN);

  constructor(readonly overrideProvisioningId?: string) {
  }

  dbRef(path?: string): DatabaseReference {
    if (!path) {
      path = "";
    }
    if (!path.startsWith("/")) {
      path = "/" + path;
    }
    let prefix = "";
    if (GLOBAL_PATHS.findIndex(globalPath => path === ("/" + globalPath) || path.startsWith("/" + globalPath + "/")) < 0) {
      const provisioningId = this.overrideProvisioningId || getProvisioningId() || SystemProvisioningIds.MAIN;
      prefix = provisioningId ? "/data/" + provisioningId + "/" : "";
    }
    const db = getDatabase();
    return ref(db, prefix + path);
  }

  async dbRef_getVal(path: string): Promise<any | undefined> {
    const objectRef = this.dbRef(path);
    const result = await get(objectRef);
    if (result.exists()) {
      const val = result.val();
      for (const enumKey in SystemKeys) {
        const key = SystemKeys[enumKey];
        if (val[key]) {
          delete val[key];
        }
      }
      return val;
    }
    return undefined;
  }

  dbRef_onChildAdded(path: string, callback: (snapshot: DataSnapshot, previousChildName: string | null) => unknown, options?: ListenOptions): void {
    onChildAdded(this.dbRef(path), (snapshot, previousChildName) => {
      for (const enumKey in SystemKeys) {
        const key = SystemKeys[enumKey];
        if (snapshot.key === key) {
          return;
        }
      }
      callback(snapshot, previousChildName);
    });
  }

  dbRef_onChildChanged(path: string, callback: (snapshot: DataSnapshot, previousChildName: string | null) => unknown, options?: ListenOptions): void {
    onChildChanged(this.dbRef(path), (snapshot, previousChildName) => {
      for (const enumKey in SystemKeys) {
        const key = SystemKeys[enumKey];
        if (snapshot.key === key) {
          return;
        }
      }
      callback(snapshot, previousChildName);
    });
  }

  dbRef_onChildRemoved(path: string, callback: (snapshot: DataSnapshot | null) => unknown, options?: ListenOptions): void {
    onChildRemoved(this.dbRef(path), (snapshot) => {
      for (const enumKey in SystemKeys) {
        const key = SystemKeys[enumKey];
        if (snapshot.key === key) {
          return;
        }
      }
      callback(snapshot);
    });
  }

  async dbRef_setVal(path: string, object: any, rel?: Rel) {
    const objectRef = this.dbRef(path);
    if (rel) {
      const db = getDatabase();
      const relRef = ref(db, objectRef.parent.toString().substring(objectRef.root.toString().length - 1) + rel.path());
      await set(relRef, rel.id2);
    }
    await set(objectRef, object);
  }

  async dbRef_removeVal(path: string, rel?: Rel) {
    const objectRef = this.dbRef(path);
    await remove(objectRef);
    if (rel) {
      const db = getDatabase();
      const relRef = ref(db, objectRef.parent.toString().substring(objectRef.root.toString().length - 1) + rel.path());
      await remove(relRef)
    }
  }
}

export function dbRef(path?: string): DatabaseReference {
  return ProvisioningContext.DEFAULT.dbRef(path);
}

export async function dbRef_getVal(path: string): Promise<any | undefined> {
  return ProvisioningContext.DEFAULT.dbRef_getVal(path);
}

export function dbRef_onChildAdded(path: string, callback: (snapshot: DataSnapshot, previousChildName: string | null) => unknown, options?: ListenOptions): void {
  return ProvisioningContext.DEFAULT.dbRef_onChildAdded(path, callback, options);
}

export async function dbRef_setVal(path: string, object: any, rel?: Rel) {
  return ProvisioningContext.DEFAULT.dbRef_setVal(path, object, rel);
}

export async function dbRef_removeVal(path: string, rel?: Rel) {
  return ProvisioningContext.DEFAULT.dbRef_removeVal(path, rel);
}
