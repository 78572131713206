import {
  BORDER_RADIUS,
  BORDER_RADIUS_SMALL,
  DIVIDER, PAGE_FRAGMENT_LG_WIDTH,
  PAGE_FRAGMENT_WIDTH,
  PAGE_FRAGMENT_XLG_WIDTH,
  PAGE_FRAGMENT_XXLG_WIDTH,
  PD,
  PD_MD,
  PD_SM,
  PD_XLG,
  PD_XLG2,
  PD_XSM,
  PD_XXLG,
  PD_XXSM,
  SZ_JUMBO,
  SZ_MD,
  SZ_SM,
  SZ_THUMBNAIL,
  SZ_XXLG
} from "./dimens";
import {Badge, Box, BoxProps, Button, Card, styled, SvgIcon, Typography, useMediaQuery, useTheme} from "@mui/material";
import React, {ReactElement} from "react";
import {black, colorGreen, colorHighlightAlt, colorOrange, lightGray, mediumGray, white} from "./colors";
import {EmptyConfig} from "@/shared-site/types";

// import defaultImage from "res/images/default_photo_album.png";

export function StyledSpan() {
  return <span style={{flexGrow: 1}}/>
}

export function StyledVisible(props: { visible: boolean, style?: any, children: any }) {
  return <Box style={{display: props.visible ? "flex" : "none", ...props.style}}>
    {props.children}
  </Box>;
}

export function StyledBoxRow(props: BoxProps) {
  return <Box id={props.id} style={{display: "flex", gap: PD_SM, ...props.style}}>
    {props.children}
  </Box>;
}

export function StyledBoxColumn(props: BoxProps & { gutter?: boolean }) {
  return <Box id={props.id} style={{
    display: "flex",
    flexDirection: "column",
    gap: PD_SM,
    paddingLeft: props.gutter ? PD_SM : 0,
    paddingRight: props.gutter ? PD_SM : 0,
    ...props.style,
  }}>
    {props.children}
  </Box>;
}

export function StyledBoxColumnCard(props: BoxProps & { title: string }) {
  return <Card>
    <Box style={{
      display: "flex",
      flexDirection: "column",
      gap: PD_SM,
      ...props.style
    }}>
      <StyledBoxRow style={{padding: PD_SM, borderBottom: DIVIDER}}>
        <Typography fontWeight="bold">{props.title}</Typography>
      </StyledBoxRow>
      <StyledBoxColumn style={{flexGrow: 1, alignItems: "center", padding: PD_SM, marginTop: -PD_SM}}>
        {props.children}
      </StyledBoxColumn>
    </Box>
  </Card>;

}

const SIZES = {
  "xxlg": PAGE_FRAGMENT_XXLG_WIDTH,
  "xlg": PAGE_FRAGMENT_XLG_WIDTH,
  "lg": PAGE_FRAGMENT_LG_WIDTH,
  "md": PAGE_FRAGMENT_WIDTH,
}

export function StyledContainer(props: BoxProps & { size?: "xxlg" | "xlg" | "lg"| "md" }) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  return <StyledBoxColumn id={props.id} style={{
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexGrow: "1",
    maxWidth: SIZES[props.size || "xlg"],
    marginLeft: "auto",
    marginRight: "auto"
  }}>
    <StyledBoxColumn style={{
      paddingLeft: sm ? PD_XLG : PD_MD,
      paddingRight: sm ? PD_XLG: PD_MD,
      paddingTop: PD_XXLG,
      paddingBottom: PD_XXLG,
      gap: PD_XLG2, ...props.style
    }}>
      {props.children}
    </StyledBoxColumn>
  </StyledBoxColumn>;
}

export const StyledBadge = styled(Badge)(({theme}) => ({
  '& .MuiBadge-badge': {
    right: PD_MD,
    top: PD_MD,
    background: colorOrange,
    color: white,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: PD_SM,
  },
}));

export function StyledEmpty(props: { emptyConfig: EmptyConfig, style?: any }) {
  let emptyConfig = props.emptyConfig;
  if (!emptyConfig) {
    return null;
  }
  let IconType = emptyConfig.iconType;
  let style = props.style || {};
  return <Box style={{
    display: "flex",
    flexDirection: "column",
    color: mediumGray,
    background: lightGray,
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: PD_XXLG,
    ...style,
  }}>
    <Box style={{
      display: "flex",
      flexDirection: "column",
      maxWidth: 360,
      alignItems: "center",
    }}>
      <IconType style={{fontSize: 72}}/>
      <Typography variant="h5" style={{marginTop: PD_SM}}>{emptyConfig.title}</Typography>
      {Boolean(emptyConfig.text) ?
        <Typography style={{marginTop: PD_SM, textAlign: "center"}}>{emptyConfig.text}</Typography>
        : null}
      <Box style={{display: "flex", alignItems: "center", gap: PD_SM}}>
        {Boolean(emptyConfig.action) ?
          <Button variant="contained" style={{marginTop: PD_MD, textTransform: "uppercase"}}
                  onClick={(event) => emptyConfig.action.onClick(event)}>
            {emptyConfig.action.text}
          </Button>
          : null}
        {Boolean(emptyConfig.altAction) ? <Button
            variant="outlined" style={{marginTop: PD_MD, textTransform: "uppercase"}}
            onClick={(event) => emptyConfig.altAction.onClick(event)}>{emptyConfig.altAction.text}</Button>
          : null}
      </Box>
    </Box>
  </Box>;
}

export type StyledListItemProps<T> = {
  disabled?: boolean,
  defaultImage?: string,
  path?: string,
  object?: T,
  variant?: "text" | "default" | "thumbnail",
  size?: "lg" | "md" | "sm",
  selected?: boolean,
  img?: string,
  icon?: typeof SvgIcon,
  iconForegroundColor?: string,
  iconBackgroundColor?: string,
  title: string,
  text?: string,
  titleSecondary?: string,
  textSecondary?: string,
  accessory?: ReactElement,
  noLink?: boolean,
  onClick?: (object: T) => void | undefined,
}

const LIST_ITEM_THUMBNAIL_SIZE_MAP = new Map<string, number>([
  ["lg", SZ_JUMBO * 1.5],
  ["md", SZ_THUMBNAIL],
  ["sm", SZ_XXLG],
]);

export function StyledListItem<T>(props: StyledListItemProps<T>) {
  const theme = useTheme();
  switch (props.variant || "default") {
    case "default":
      return <Button
        disabled={props.disabled}
        variant="text"
        // to={props.path}
        style={{
          color: black,
          background: props.selected ? colorHighlightAlt : undefined,
        }}
        onClick={() => props.onClick?.(props.object)}>
        <Box style={{
          width: "100%",
          height: 72,
          display: "flex",
          gap: PD_SM,
          textTransform: "none",
          alignItems: "center",
        }}>
          {props.icon ?
            <Box style={{
              width: SZ_MD,
              height: SZ_MD,
              flexShrink: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: props.iconBackgroundColor || theme.palette.primary.main,
              borderRadius: BORDER_RADIUS
            }}>
              <props.icon style={{color: props.iconForegroundColor || "white"}}/>
            </Box>
            : <Card style={{width: SZ_MD, height: SZ_MD, flexShrink: 0}}>
              <img src={props.img || props.defaultImage} style={{width: "100%", height: "100%", objectFit: "cover"}}/>
            </Card>
          }
          <Box style={{
            display: "flex", flexDirection: "column", flexGrow: 1, whiteSpace: "nowrap", overflow: "hidden",
          }}>
            <Box style={{display: "flex", alignItems: "center", gap: PD_XXSM}}>
              <Typography style={{
                fontWeight: "bold",
                textAlign: "start",
                fontSize: "120%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                flexGrow: 1,
              }}>{props.title}</Typography>
              {props.titleSecondary ?
                <Typography style={{
                  flexGrow: 0,
                  flexShrink: 0,
                }}>{props.titleSecondary}</Typography>
                : null}
            </Box>
            <Box style={{display: "flex", alignItems: "center", gap: PD_XXSM}}>
              <Typography variant="body2"
                          style={{
                            textAlign: "start",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}>{props.text}</Typography>
            </Box>
          </Box>
          {props.accessory}
        </Box>
      </Button>;
    case "thumbnail":
      const size = LIST_ITEM_THUMBNAIL_SIZE_MAP.get(props.size || "md");
      return <Box style={{background: props.selected ? colorGreen : undefined, padding: PD_XSM}}>
        <Button
          color="secondary"
          variant="text"
          style={{
            borderRadius: BORDER_RADIUS_SMALL,
            width: size,
            height: size,
            padding: 8,
            display: "flex",
            flexDirection: "column",
            gap: PD_SM,
            textTransform: "none",
            alignItems: "center",
            position: "relative",
          }}
          onClick={() => props.onClick?.(props.object as T)}>
          <Card style={{
            position: "absolute",
            borderRadius: BORDER_RADIUS_SMALL,
            left: 0,
            top: 0,
            width: "100%",
            height: "100%"
          }}>
            <img src={props.img} style={{width: "100%", height: "100%", objectFit: "cover"}}/>
          </Card>
          <Box style={{position: "absolute", right: 0, top: 0}}>
            {props.accessory}
          </Box>
        </Button>
      </Box>;
  }
  return null;
}

export class StyledVerticalStack extends React.PureComponent<{
  label?: string,
  gap?: string | boolean,
  gutterLabel?: boolean,
  style?: any,
  children?: any
}> {
  render() {
    let style = this.props.style || {};
    return <Box style={{
      ...style,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      marginTop: this.props.gap ? (typeof this.props.gap === "string" ? PD[this.props.gap] : (Boolean(this.props.gap) ? PD_SM : 0)) : 0,
    }}>
      {this.props.label ? <Typography variant="body2"
                                      style={{
                                        display: "block",
                                        marginLeft: this.props.gutterLabel ? PD_SM : 0,
                                        marginBottom: PD_XXSM,
                                      }}>{this.props.label}</Typography>
        : null}
      {this.props.children}
    </Box>;
  }
}

export class StyledHorizontalStack extends React.PureComponent<{
  label?: string,
  gap?: string | boolean,
  gutterLabel?: boolean,
  style?: any,
  children?: any
}> {
  render() {
    let style = this.props.style || {};
    return <Box style={{
      ...style,
      width: "100%",
      height: SZ_SM,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: PD_SM,
      marginTop: this.props.gap ? (typeof this.props.gap === "string" ? PD[this.props.gap] : (Boolean(this.props.gap) ? PD_SM : 0)) : 0,
    }}>
      {this.props.label ? <Box style={{
          display: "flex",
          width: 96,
          flexGrow: 0,
          flexShrink: 0,
          justifyContent: "flex-end",
        }}><Typography variant="caption">{this.props.label}</Typography>
        </Box>
        : null}
      <Box style={{
        flexGrow: 1,
      }}>
        {this.props.children}
      </Box>
    </Box>;
  }
}

export class StyledToolstripTool {
  constructor(readonly name: string, readonly iconType: typeof SvgIcon, readonly hidden?: boolean) {
  }
}

type StyledToolstripProps = {
  orientation?: "horizontal" | "vertical",
  floating?: boolean,
  tools: StyledToolstripTool[],
  initialSelectedToolIndex: number,
  onSelectedToolChanged: (tool) => void,
}

type StyledToolstripState = {
  selectedTool: string,
}

export type StyledDashboardWidgetRenderer = {
  title?: string,
  rows: number,
  columns: number,
  render: (width: number, height: number) => ReactElement,
}

export type StyledDashboardWidgetProps = {
  cellSize: number,
  renderer: StyledDashboardWidgetRenderer,
}

type StyledDashboardWidgetState = {}

export class StyledDashboardWidget extends React.Component<StyledDashboardWidgetProps, StyledDashboardWidgetState> {

  render(): ReactElement {
    const width = this.props.cellSize * this.props.renderer.columns;
    const height = this.props.cellSize * this.props.renderer.rows;
    return <Box style={{
      display: "flex",
      flexDirection: "column",
      margin: PD_MD,
      padding: PD_SM,
      borderRadius: BORDER_RADIUS,
      border: DIVIDER,
      background: white
    }}>
      {this.props.renderer.title ?
        <Typography style={{fontWeight: "bold", padding: PD_SM}}>{this.props.renderer.title}</Typography>
        : null}
      <Box style={{width: width, height: height}}>
        {this.props.renderer.render(width, height)}
      </Box>
    </Box>;
  }
}

export type StyledDashboardWidgetContainerProps = {
  renderers: StyledDashboardWidgetRenderer[],
}

export class StyledDashboardWidgetContainer extends React.PureComponent<StyledDashboardWidgetContainerProps> {

  render(): ReactElement {
    return <StyledBoxRow style={{flexGrow: 1, alignItems: "flex-start"}}>
      {this.props.renderers.map(renderer => <StyledDashboardWidget cellSize={72} renderer={renderer}/>)}
    </StyledBoxRow>
  }
}
