import {Box, Button, Rating, TextField, Typography} from "@mui/material";
import {DW_SM, PD_MD} from "shared/dimens";
import React from "react";
import {BASE_STATE, BaseFragment, BaseFragmentState} from "shared/BaseFragment";
import {get, getDatabase, push, set} from "@firebase/database";
import {getAuth} from "@firebase/auth";
import {BaseApp, renderStamp} from "./BaseApp";
import {dbRef} from "./database";

export type AboutFragmentProps = {}

type AboutFragmentState = BaseFragmentState & {
  rating: number,
  feedbackText: string,
}

type Rating = {
  rating: number,
  time: number,
}

type Feedback = {
  text: string,
  time: number,
}

export class AboutFragment extends BaseFragment<AboutFragmentProps, AboutFragmentState> {

  private readonly auth = getAuth();

  constructor(props: AboutFragmentProps, context: any) {
    super(props, context);
    this.state = {
      ...BASE_STATE,
      rating: 0,
      feedbackText: "",
    }
  }

  componentDidUpdate(prevProps: Readonly<AboutFragmentProps>, prevState: Readonly<AboutFragmentState>, snapshot?: any) {
    if (this.state.rating !== 0 && prevState.rating !== this.state.rating) {
      const db = getDatabase();
      set(dbRef("ratings/" + this.auth.currentUser?.uid), {
        rating: this.state.rating,
        time: Date.now(),
      } as Rating)
        .catch(error => BaseApp.CONTEXT.showToast("Oops. Something went wrong: Rating failed."));
    }
  }

  protected async fetchOnMount(): Promise<void> {
    const db = getDatabase();
    const ratingRef = dbRef("ratings/" + this.auth.currentUser?.uid);
    const result = await get(ratingRef);
    if (result.exists()) {
      const rating = result.val() as Rating;
      this.setState({
        rating: rating.rating,
      });
    }
  }

  protected renderContainerContent(): React.ReactElement | null {
    const text = "How would you rate " + BaseApp.CONTEXT.getAppConfig().name + "?";
    return <Box style={{display: "flex", flexDirection: "column", maxWidth: DW_SM, gap: PD_MD, padding: PD_MD}}>
      <Box style={{width: 240, aspectRatio: 2, alignSelf: "center", marginTop: 64}}>
        {renderStamp()}
      </Box>
      <Typography style={{textAlign: "center"}}>{text}</Typography>
      <Rating
        style={{alignSelf: "center"}}
        value={this.state.rating}
        onChange={(event, newValue) => {
          this.setState({rating: newValue});
        }}
      />
      <Typography style={{textAlign: "center"}}>If you have any feedback for us, please type it in the box below and
        press
        the Send button.</Typography>
      <TextField value={this.state.feedbackText} multiline minRows={3}
                 onChange={event => this.setState({feedbackText: event.target.value})}/>
      <Button variant="contained" onClick={() => {
        BaseApp.CONTEXT.hideDialog();
        const db = getDatabase();
        set(push(dbRef("feedback/" + this.auth.currentUser?.uid)), {
          text: this.state.feedbackText,
          time: Date.now(),
        } as Feedback)
          .then(() => BaseApp.CONTEXT.showToast("Sent."))
          .catch(error => BaseApp.CONTEXT.showToast("Oops. Something went wrong: Feedback send failed."));
      }
      }>Send</Button>
    </Box>;
  }
}
