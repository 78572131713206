import React, {useState} from "react";
import {KeyTextString} from "./types";
import {Autocomplete, Chip, CircularProgress, TextField} from "@mui/material";
import {FormGenContentItem, FormGenLayoutSource} from "./FormGenContainer";
import {SZ_XSM} from "./dimens";
import {validateEmail} from "./text_util";
import {colorPastelRed} from "./colors";

export type FormGenFreetextType = "text" | "email";

class FormGenAutocompleteOption {
  constructor(readonly type: "keytext" | "freetext", readonly item: KeyTextString) {
  }
}


function valuesToOptions(newValues: any | any[], options: FormGenAutocompleteOption[], freetextType?: FormGenFreetextType): FormGenAutocompleteOption[] {
  if (Array.isArray(newValues)) {
    return newValues.map(value => valueToOption(value, options, freetextType));
  }
  return [valueToOption(newValues, options, freetextType)];
}

function valueToOption(value: FormGenAutocompleteOption | string, options: FormGenAutocompleteOption[], freetextType?: FormGenFreetextType): FormGenAutocompleteOption {
  if (value instanceof FormGenAutocompleteOption) {
    return value as FormGenAutocompleteOption;
  }
  if (freetextType) {
    const str = value as string;
    return new FormGenAutocompleteOption("freetext", new KeyTextString(freetextType + ":" + str, str));
  }
  return options.find(option => option.item.key === value);
}

export function FormGenAutocomplete(props: { contentItem: FormGenContentItem, index: number, source: FormGenLayoutSource }) {
  const [currentOption, setCurrentOption] = useState<FormGenAutocompleteOption>();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<FormGenAutocompleteOption[]>([]);
  const [reloadOptions, setReloadOptions] = useState(false);
  const [inputText, setInputText] = useState("");

  React.useEffect(() => {
    (async () => {
      const value = props.source.getValue(props.contentItem);
      const options: FormGenAutocompleteOption[] = (await props.contentItem.metadata.autocompleteLoader(value))?.map(keytext => new FormGenAutocompleteOption("keytext", keytext));
      setOptions(options);
      if (options?.length > 0) {
        setCurrentOption(options[0]);
      }
    })();
  }, []);

  const loading = open && options.length === 0;
  React.useEffect(() => {
    let active = true;

    if (!loading && !reloadOptions) {
      return undefined;
    }

    (async () => {
      const value = props.source.getValue(props.contentItem);
      const options: FormGenAutocompleteOption[] = [];
      options.push(...(await props.contentItem.metadata.autocompleteLoader(value, inputText))?.map(keytext => new FormGenAutocompleteOption("keytext", keytext)));
      if (currentOption) {
        options.push(currentOption);
      }
      if (active) {
        setReloadOptions(false);
        setOptions(options);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, reloadOptions]);

  React.useEffect(() => {
    if (!open) {
      setOptions([currentOption]);
    }
  }, [open]);

  const multiple = props.contentItem.metadata.autocompleteMulti;
  return <Autocomplete
    key={currentOption?.item.key}
    disablePortal
    autoSelect
    multiple={multiple}
    filterSelectedOptions
    freeSolo={Boolean(props.contentItem.metadata.autocompleteFreetextType)}
    renderTags={(values: any, getTagProps) =>
      values.map((value, index) => {
        let backgroundColor = null;
        const option = valueToOption(value, options, props.contentItem.metadata.autocompleteFreetextType);
        if (option.type === "freetext" && props.contentItem.metadata.autocompleteFreetextType === "email" && !validateEmail(option.item.text)) {
          backgroundColor = colorPastelRed;
        }
        console.log("returning chip");
        return <Chip style={{backgroundColor: backgroundColor}}
                     label={option.item.text} {...getTagProps({index})} />
      })
    }
    open={open}
    onOpen={() => {
      setOpen(true);
    }}
    onClose={() => {
      setOpen(false);
    }}
    value={currentOption}
    onChange={(event: any, newValue) => {
      const toOptions: FormGenAutocompleteOption[] = valuesToOptions(newValue, options, props.contentItem.metadata.autocompleteFreetextType);
      const value = multiple ? toOptions.map(option => option.item.key) : toOptions[0]?.item.key;
      props.source.setValue(props.contentItem, value);
      setCurrentOption(valueToOption(value as string, options));
    }}
    inputValue={inputText}
    onInputChange={(event, newInputValue) => {
      setInputText(newInputValue);
      if (props.contentItem.metadata.autocompleteResetOnChange) {
        setReloadOptions(true);
      }
    }}
    // getOptionSelected={(option, value) => option.key === value.key}
    //@ts-ignore
    getOptionLabel={(option) => option?.item?.text}
    options={options}
    loading={loading}
    renderInput={(params) => (
      <TextField
        {...params}
        //label={props.contentItem.metadata.name}
        variant="outlined"
        size="small"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={SZ_XSM}/> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
      />
    )}
  />;
}
