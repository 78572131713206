import {BlobType, LocalFile} from "./types";
import {Entity} from "./entities";
import {itemToJSONArray, jsonToItemArray} from "./json_util";

export abstract class BaseFile {

  protected constructor(readonly mimeType: string, readonly length: number, readonly extras?: any) {
  }

  toJSON(): any {
    return {
      mimeType: this.mimeType,
      length: this.length,
      extras: this.extras,
    };
  }

  static fromJSONPart<T extends BaseFile>(creator: (mimeType: string, length: number, extras: any) => T, json: any): T {
    return creator(json.mimeType, json.length, json.extras);
  }
}

export class DesktopFile extends BaseFile {

  constructor(readonly file: LocalFile, readonly displayName?: string, mimeType?: string, extras?: any) {
    super(mimeType, file.size, extras);
  }
}

export class File extends BaseFile implements BlobType {

  constructor(mimeType: string, length: number, extras: any, readonly blobId: string) {
    super(mimeType, length, extras);
  }

  getBlobId(): string {
    return this.blobId;
  }

  getMimeType(): string {
    return this.mimeType;
  }

  toJSON(): any {
    return {
      ...super.toJSON(),
      blobId: this.blobId,
    };
  }
}

export class FileRequest extends BaseFile {

  private blob_: Blob;

  constructor(readonly parentId: string, readonly displayName: string, readonly orientation: string, mimeType: string, length: number, extras: any, blob: Blob) {
    super(mimeType, length, extras);
    this.blob_ = blob;
  }

  blob(): Blob {
    return this.blob_;
  }

  toJSON(): any {
    return {
      ...super.toJSON(),
      parentId: this.parentId,
      displayName: this.displayName,
      orientation: this.orientation,
    };
  }
}

export class FileNodeRenditionImagesImage {

  constructor(readonly jpg: string, readonly png: string) {
  }

  imageUrl(): string {
    return Boolean(this.jpg) ? this.jpg : this.png;
  }

  toJSON(): any {
    return {
      jpg: this.jpg,
      png: this.png,
    };
  }

  static fromJSON(json: any): FileNodeRenditionImagesImage {
    if (!json) {
      return undefined;
    }
    return new FileNodeRenditionImagesImage(json.jpg, json.png);
  }
}

export class FileNodeRenditionImages {

  constructor(readonly full: FileNodeRenditionImagesImage) {
  }

  toJSON(): any {
    return {
      full: this.full?.toJSON(),
    };
  }

  static fromJSON(json: any): FileNodeRenditionImages {
    if (!json) {
      return undefined;
    }
    return new FileNodeRenditionImages(FileNodeRenditionImagesImage.fromJSON(json.full));
  }
}

export class FileNodeRenditionAudiosAudio {

  constructor(readonly mp3: string) {
  }

  audioUrl(): string {
    return this.mp3;
  }

  toJSON(): any {
    return {
      mp3: this.mp3,
    };
  }

  static fromJSON(json: any): FileNodeRenditionAudiosAudio {
    if (!json) {
      return undefined;
    }
    return new FileNodeRenditionAudiosAudio(json.mp3);
  }
}

export class FileNodeRenditionAudios {

  constructor(readonly full: FileNodeRenditionAudiosAudio) {
  }

  toJSON(): any {
    return {
      full: this.full?.toJSON(),
    };
  }

  static fromJSON(json: any): FileNodeRenditionAudios {
    if (!json) {
      return undefined;
    }
    return new FileNodeRenditionAudios(FileNodeRenditionAudiosAudio.fromJSON(json.full));
  }
}

export class FileNodeRenditions {

  constructor(readonly images: FileNodeRenditionImages, readonly audios: FileNodeRenditionAudios) {
  }

  toJSON(): any {
    return {
      images: this.images?.toJSON(),
    };
  }

  static fromJSON(json: any): FileNodeRenditions {
    if (!json) {
      return undefined;
    }
    return new FileNodeRenditions(FileNodeRenditionImages.fromJSON(json.images), FileNodeRenditionAudios.fromJSON(json.audios));
  }
}

// export abstract class BaseFileNode extends Entity {
//
//   static readonly TYPE_OBJECT = 0;
//   static readonly TYPE_GROUP = 1;
//   static readonly TYPE_LINK = 2;
//
//   constructor(readonly id: string, readonly parentId: string, displayName: string, profilePhoto: string, readonly type: number, readonly created: number, readonly creator: string, readonly modified: number, readonly renditions: FileNodeRenditions, readonly thumbnailUrl: string) {
//     super(displayName, profilePhoto);
//   }
//
//   toJSON(): any {
//     return {
//       id: this.id,
//       parentId: this.parentId,
//       type: this.type,
//       created: this.created,
//       creator: this.creator,
//       modified: this.modified,
//       renditions: this.renditions,
//       thumbnailUrl: this.thumbnailUrl,
//     }
//   }
//
//   static fromJSON(json: any): BaseFileNode {
//     switch (json.type) {
//       case this.TYPE_OBJECT:
//         return FileObject.fromJSON(json);
//       case this.TYPE_GROUP:
//         return FileGroup.fromJSON(json);
//     }
//     return null;
//   }
//
//   static fromBaseFileNodeJSONPart<T extends BaseFileNode>(creator: (id: string, parentId: string, displayName: string, profilePhoto: string, type: number, created: number, creator: string, modified: number, renditions: FileNodeRenditions, thumbnailUrl: string) => T, json: any): T {
//     return Entity.fromJSONPart((displayName, profilePhoto) => creator(json.id, json.parentId, displayName, profilePhoto, json.type, json.created, json.creator, json.modified, FileNodeRenditions.fromJSON(json.renditions), json.thumbnailUrl), json);
//   }
// }
//
// export class FileObject extends BaseFileNode {
//
//   constructor(id: string, parentId: string, displayName: string, profilePhoto: string, type: number, created: number, creator: string, modified: number, renditions: FileNodeRenditions, thumbnailUrl: string, readonly mimeType: string, readonly orientation: string, readonly length: number) {
//     super(id, parentId, displayName, profilePhoto, type, created, creator, modified, renditions, thumbnailUrl);
//   }
//
//   toJSON(): any {
//     return {
//       ...super.toJSON(),
//       mimeType: this.mimeType,
//       orientation: this.orientation,
//       length: this.length,
//     };
//   }
//
//   static fromJSON(json: any): FileObject {
//     return BaseFileNode.fromBaseFileNodeJSONPart((id, parentId, displayName, profilePhoto, type, created, creator, modified, renditions, thumbnailUrl) => {
//       console.assert(type === BaseFileNode.TYPE_OBJECT)
//       return new FileObject(id, parentId, displayName, profilePhoto, BaseFileNode.TYPE_OBJECT, created, creator, modified, renditions, thumbnailUrl, json.mimeType, json.orientation, json.length);
//     }, json);
//   }
// }
//
// export class FileGroup extends BaseFileNode {
//
//   constructor(id: string, parentId: string, displayName: string, profilePhoto: string, type: number, created: number, creator: string, modified: number, renditions: FileNodeRenditions, thumbnailUrl: string, readonly shareId: string, readonly groupId: string, readonly kidsCount: number, readonly contentPreview: BaseFileNode[]) {
//     super(id, parentId, displayName, profilePhoto, type, created, creator, modified, renditions, thumbnailUrl);
//   }
//
//   toJSON(): any {
//     return {
//       ...super.toJSON(),
//       kidsCount: this.kidsCount,
//       contentPreview: itemToJSONArray(this.contentPreview),
//     };
//   }
//
//   static fromJSON(json: any): FileGroup {
//     return BaseFileNode.fromBaseFileNodeJSONPart((id, parentId, displayName, profilePhoto, type, created, creator, modified, renditions, thumbnailUrl) => {
//       console.assert(type === BaseFileNode.TYPE_GROUP)
//       return new FileGroup(id, parentId, displayName, profilePhoto, BaseFileNode.TYPE_GROUP, created, creator, modified, renditions, thumbnailUrl, json.shareId, json.groupId, json.kidsCount, jsonToItemArray(BaseFileNode, json.contentPreview));
//     }, json);
//   }
// }
//
// export class FileCollection extends FileGroup {
//
//   constructor(id: string, parentId: string, displayName: string, profilePhoto: string, type: number, created: number, creator: string, modified: number, renditions: FileNodeRenditions, thumbnailUrl: string, shareId: string, groupId: string, kidsCount: number, contentPreview: BaseFileNode[]) {
//     super(id, parentId, displayName, profilePhoto, type, created, creator, modified, renditions, thumbnailUrl, shareId, groupId, kidsCount, contentPreview);
//   }
// }
