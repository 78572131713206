import React, {ReactElement} from "react";
import {Box, Button, Typography} from "@mui/material";
import {DialogFragment, PageFragment, PageFragmentProps, PageFragmentState} from "shared/PageFragment";
import {BORDER_RADIUS, DW_LG, PD_MD, PD_SM, PD_XXLG, SZ_MD} from "shared/dimens";
import {CloudUploadOutlined, InsertDriveFileOutlined} from "@mui/icons-material";
import {colorGray, mediumGray} from "shared/colors";
import {DesktopFile} from "shared/files";
import {BaseApp} from "./BaseApp";
import {StyledBoxColumn, StyledUploadButton} from "./StyledComponents";
import {MimeTypeMap} from "./mime_types";


export type BaseUploadFragmentProps = PageFragmentProps & {
  multiple?: boolean,
  select?: boolean,
  onUploadDone?: (urls: string[]) => void,
  onSelectionChanged?: (urls: string[]) => void,
  //showContentPreview?: (content: HivemindItemContent) => void,
}

export type BaseUploadFragmentState = PageFragmentState & {
  urls?: string[],
}

export abstract class BaseUploadFragment<P extends BaseUploadFragmentProps, S extends BaseUploadFragmentState> extends DialogFragment<P, S> {

  protected onCreateState(): S {
    return {
      ...super.onCreateState(),
      title: "Upload",
      actionButtonDisabled: true,
    };
  }

  protected styleFlags(): number {
    return this.props.select ? PageFragment.STYLE_ACTION_PANEL_TYPE_BUTTON_FLAG : 0;
  }

  protected getActionButtonText(): string {
    return "Select";
  }

  onActionButtonClicked() {
    this.props.onSelectionChanged?.(this.state.urls);
    BaseApp.CONTEXT.hideDialog();
  }

  renderContent(): React.ReactElement {
    return <Box style={{display: "flex", flexDirection: "column", width: DW_LG, padding: PD_XXLG}}>
      {!this.state.urls ? this.renderNoFile() : this.renderUploadedFile()}
    </Box>;
  }

  private renderUploadedFile(): ReactElement {
    return <>
      {this.state.urls?.map(url => {
        if (MimeTypeMap.isImage(MimeTypeMap.mimeTypeFromExtension(MimeTypeMap.getFileExtensionFromUrl(url)))) {
          return <img src={url}
                      style={{width: "100%", height: "100%", objectFit: "cover", borderRadius: BORDER_RADIUS}}/>;
        }
        return this.renderFile(url);
      })}
    </>;
  }

  private renderNoFile(): ReactElement {
    const emptyConfig = {
      iconType: CloudUploadOutlined,
      title: "Drag and drop file(s) here",
      text: "or use the Upload button to add files",
    };
    let IconType = emptyConfig.iconType;
    return <StyledBoxColumn style={{
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <IconType style={{fontSize: SZ_MD, color: colorGray}}/>
      <Typography variant="h6" style={{color: colorGray, marginTop: PD_SM}}>{emptyConfig.title}</Typography>
      {Boolean(emptyConfig.text) ?
        <Typography>{emptyConfig.text}</Typography>
        : null}
      <Box style={{display: "flex", alignItems: "center", gap: PD_SM}}>
        <StyledUploadButton
          style={{marginTop: PD_MD}}
          mimeTypes={this.getMimeTypes()}
          uploadFile={(file: DesktopFile) => this.onUploadFile(file)}
        />
      </Box>
    </StyledBoxColumn>;
  }

  private renderFile(url: string): ReactElement {
    const config = {
      iconType: InsertDriveFileOutlined,
      title: "File path",
      text: url,
    };
    let IconType = config.iconType;
    return <Box style={{
      display: "flex",
      flexDirection: "column",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <IconType style={{fontSize: SZ_MD, color: colorGray}}/>
      <Typography variant="h6" style={{color: colorGray, marginTop: PD_SM}}>{config.title}</Typography>
      {Boolean(config.text) ?
        <Typography variant="caption" style={{color: mediumGray}}>{config.text}</Typography>
        : null}
      <Box style={{display: "flex", alignItems: "center", gap: PD_SM}}>
        <Button
          variant="contained"
          style={{marginTop: PD_MD}}
          onClick={() => {
          }}
        >Copy path</Button>
      </Box>
    </Box>;
  }

  protected setUploadedFiles(urls: string[]) {
    this.setState({
      actionButtonDisabled: !(urls?.length > 0),
      urls: urls,
    });
    if (!this.props.select) {
      this.props.onUploadDone?.(urls);
    }
  }

  protected abstract getMimeTypes(): string[];

  protected abstract onUploadFile(file: DesktopFile): Promise<any>;
}
