import {SvgIcon} from "@mui/material";
import {Action, CreateActions} from "./types";

export interface TableDataMetadata {
  cellId?: string,
  name: string;
  type?: "string" | "string_array" | "profile_photo" | "checkbox" | "color" | "actions" | "custom";
  actionsIcon?: typeof SvgIcon,
  actions?: Action[] | CreateActions,
  disabled?: boolean,
  cellStyle?: any,

  _propertyKey?: string,
}

export const tableDataMetadataKey = (propertyKey: string) => "__tabledata_" + propertyKey + "_metadata";

export function TableData(metadata: TableDataMetadata) {
  return function (target: any, propertyKey: string) {
    metadata._propertyKey = propertyKey;
    target[tableDataMetadataKey(propertyKey)] = metadata;
  };
}
