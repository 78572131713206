import {BaseContent, BaseContentLoader} from "../cmsshared-admin/content/types";
import {BaseContentTableDataRowEx, BaseContentTableFragment} from "../cmsshared-admin/content/BaseContentTableFragment";
import {JsonProperty} from "../shared/json/json-property";
import {JsonObject} from "../shared/json/json-object";
import {TableData} from "../shared/tabledata";
import {FormGen} from "../shared/formgen";
import {BaseEditContentFragment} from "../cmsshared-admin/content/BaseEditContentFragment";
import {md5_uuid} from "../shared/md5";
import {getMemberAuth} from "../shared/auth";
import React, {ReactElement} from "react";
import {StyledBoxColumn, StyledBoxRow, StyledSpan} from "@/shared/StyledComponents";
import {Post1} from "@/cmsblog-elements/themes/classic/post/Post1";
import {DIVIDER, DW_SM, DW_XL, PD_SM} from "@/shared/dimens";
import {PageFragment, PageFragmentProps, PageFragmentState} from "@/shared/PageFragment";
import {$KTS, Action} from "@/shared/types";
import {DateUtil} from "@/shared/date_util";

@JsonObject()
export class Post extends BaseContent {

  @FormGen({name: "Status", type: "enum", enumDefaultKey: "draft", enumVariant: "button", enumValues: [$KTS("draft", "Draft"), $KTS("published", "Published"), $KTS("archived", "Archived")]})
  @JsonProperty()
  status: string;

  @FormGen({name: "Published", type: "datenum"})
  @JsonProperty()
  published: number;

  @FormGen({name: "Category", type: "enum", enumDefaultKey: "announcement", enumVariant: "select", enumValues: [$KTS("announcement", "Announcement"), $KTS("press_release", "Press release"), $KTS("product", "Product")]})
  @JsonProperty()
  category: string;

  @FormGen({name: "Title", type: "string"})
  @JsonProperty()
  title: string;

  @FormGen({name: "Subtitle", type: "string"})
  @JsonProperty()
  subtitle: string;

  @FormGen({
    name: "Cover image",
    type: "file",
    fileMimeTypes: ["image/png", "image/jpg"],
    fileStoredDataSource: "posts/cover_images"
  })
  @JsonProperty()
  coverImageUrl: string;

  @FormGen({name: "Body", type: "text"})
  @JsonProperty()
  body: string;

  @FormGen({name: "Attachments", type: "array_file", fileMimeTypes: ["image/png", "image/jpg"]})
  @JsonProperty()
  attachments: string[];

  static createNew(): Post {
    return new Post(md5_uuid(), getMemberAuth().getMemberId(), Date.now());
  }

  constructor(id: string, creator: string, created: number) {
    super(id, creator, created);
  }
}

export class PostsLoader extends BaseContentLoader<Post> {

  private static instance;

  static getInstance(): PostsLoader {
    if (!this.instance) {
      this.instance = new PostsLoader();
    }
    return this.instance;
  }

  private constructor() {
    super("posts", "post", Post);
  }
}

export class PostTableDataRow extends BaseContentTableDataRowEx {

  @TableData({name: "Status"})
  status: string;

  @TableData({name: "Published"})
  published: string;

  @TableData({name: "Category"})
  category: string;

  @TableData({name: "Title", cellStyle: {maxWidth: 192}})
  title: string;
}

export class PostsTableFragment extends BaseContentTableFragment<Post, PostsLoader, PostTableDataRow> {

  createTableDataRow(): PostTableDataRow {
    return new PostTableDataRow();
  }

  applyTableDataToRow(data: Post, row: PostTableDataRow) {
    super.applyTableDataToRow(data, row);
    row.status = data.status;
    row.published = data.published ? DateUtil.formatDateTime(data.published) : "";
    row.category = data.category;
    row.title = data.title;
  }
}

type PostPreviewFragmentProps = PageFragmentProps & {
  content: Post,
}

class PostPreviewFragment extends PageFragment<PostPreviewFragmentProps> {

  protected onCreateState(): PageFragmentState {
    return {
      ...super.onCreateState(),
      title: "Preview",
    };
  }

  protected styleFlags(): number {
    return PageFragment.STYLE_TOOLBAR_TYPE_FLAG;
  }

  protected getToolbar(toolbarModeId: string | null): React.ReactElement {
    return <StyledBoxRow style={{padding: PD_SM, flexGrow: 1}}>
      <StyledSpan />
      {this.renderToolbarButtonEx(new Action("Render", () => this.forceUpdate()).setVariant("contained"))}
    </StyledBoxRow>
  }

  renderContent(): React.ReactElement {
    return <Post1 post={this.props.content}/>;
  }
}

export class EditPostFragment extends BaseEditContentFragment<Post, PostsLoader> {

  renderContent(): ReactElement | null {
    return <StyledBoxRow style={{height: "80vh", gap: 0}}>
      <StyledBoxColumn style={{height: "100%", overflowY: "scroll"}}>
        {this.renderEditor({width: DW_SM})}
      </StyledBoxColumn>
      <StyledBoxColumn style={{height: "100%", overflowY: "scroll", width: DW_XL, borderLeft: DIVIDER}}>
        {this.renderPreview()}
      </StyledBoxColumn>
    </StyledBoxRow>;
  }

  private renderPreview(): ReactElement {
    return <PostPreviewFragment content={this.content}/>;
  }
}