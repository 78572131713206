import {JsonObject} from "shared/json/json-object";
import {JSON_OBJECT, Type} from "shared/json/helpers";
import {BaseListItem, BaseListItemsLoader} from "shared/types";
import {md5_uuid} from "../../shared/md5";
import {getMemberAuth} from "../../shared/auth";
import {BaseContentTableFragment} from "./BaseContentTableFragment";
import {BaseEditContentFragment} from "./BaseEditContentFragment";

@JsonObject()
export abstract class BaseContent extends BaseListItem {

  private type: string;

  _setType(type: string) {
    this.type = type;
  }

  getType(): string {
    return this.type;
  }

  constructor(id: string, creator: string, created: number) {
    super(id, creator, created);
  }

  clone<T extends BaseListItem>(dataType: Type<T>): T {
    // @ts-ignore
    const content = super.clone(dataType) as BaseContent;
    content._setType(this.type);
    // @ts-ignore
    return content as T;
  }
}

@JsonObject()
export class FolderContent extends BaseContent {

  @JsonObject()
  displayName: string;

  @JsonObject()
  parentId: string;

  static createNew(displayName: string, parentId?: string) {
    return new FolderContent(md5_uuid(), getMemberAuth().getMemberId(), Date.now(), displayName, parentId);
  }

  constructor(id: string, creator: string, created: number, displayName: string, parentId?: string) {
    super(id, creator, created);
    this._setType("folder");
    this.displayName = displayName;
    this.parentId = parentId;
  }
}

export abstract class BaseContentLoader<T extends BaseContent> extends BaseListItemsLoader<T> {

  protected constructor(private readonly path: string, readonly type: string, private readonly dataType: Type<T>) {
    super({shared: true});
  }

  protected basePath(): string {
    return this.path;
  }

  protected deserializeItem(value: any): T {
    const item = JSON_OBJECT.deserializeObject(value, this.dataType);
    item._setType(this.type);
    return item;
  }

  protected serializeItem(item: T): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: T, item2: T): number {
    return item2.created - item1.created;
  }
}

export type Collection<T extends BaseContent, L extends BaseContentLoader<T>> = {
  displayName: string,
  createNew: () => T,
  clone: (content: T) => T,
  loader: L,
  ContentTableFragmentType: Type<BaseContentTableFragment<T, L, any>>,
  EditContentFragmentType: Type<BaseEditContentFragment<T, L>>,
}

export class CollectionsRegistry {

  private static instance;

  static getInstance(): CollectionsRegistry {
    if (!this.instance) {
      this.instance = new CollectionsRegistry();
    }
    return this.instance;
  }

  private readonly collections: Collection<any, any>[] = [];

  private constructor() {
  }

  register(collection: Collection<any, any>) {
    if (this.collections.find(c => c.loader.type === collection.loader.type)) {
      return;
    }
    this.collections.push(collection);
  }

  get(): Collection<any, any>[] {
    return [...this.collections];
  }
}