import {Box, Button, ButtonBase, Card, Menu, MenuItem, Typography} from "@mui/material";
import {colorHighlightAlt, colorYellow, lightGray, white} from "shared/colors";
import {
  CARD_HORIZONTAL_PADDING,
  CARD_SHADOW,
  DIVIDER,
  PD_SM,
  PD_XSM,
  PD_XXSM,
  SZ_SM,
  SZ_XLG,
  SZ_XXLG
} from "shared/dimens";
import React, {Component, ReactElement} from "react";
import {ArrowBack, CloseOutlined, ExpandMore} from "@mui/icons-material";
import {ICON_BUTTON_XSM_STYLE, ICON_BUTTON_XSM_STYLE_PD} from "shared/constants";
import {Action, MenuOption, TabOptionItem, TabOptionItemGroup, TabOptions} from "shared/types";
import {BaseApp} from "./BaseApp";
import {StyledBoxRow} from "./StyledComponents";
import {PageFragment} from "./PageFragment";

type TitleBarProps = {
  text: string,
  titleHidden?: boolean,
  logo?: string,
  tabOptions?: TabOptions,
  toolbar?: ReactElement,
  buttons?: ReactElement[],
  actions?: Action[],
  popovers?: ReactElement[],
  menuOptions?: MenuOption[],
  variant?: ('decorated' | 'subdecorated' | 'none'),
  showBackButton?: boolean,
  onBackButtonClicked?(): void,
  onMenuButtonClicked?(menuOption: MenuOption): boolean,
  onCloseDialog?: () => void,
}

type TitleBarState = {
  menuOptionsAnchorEl?: any,
}

export class TitleBar extends Component<TitleBarProps, TitleBarState> {

  private readonly theme = BaseApp.CONTEXT.getAppConfig().theme;

  constructor(props: TitleBarProps, context: any) {
    super(props, context);
    this.state = {}
  }

  render() {
    const hasToolbar = Boolean(this.props.text || this.props.showBackButton || this.props.toolbar || this.props.buttons || this.props.actions || this.props.tabOptions || this.props.menuOptions);
    if (!hasToolbar) {
      return null;
    }
    return <Box style={{
      display: "flex",
      flexShrink: 0,
      gap: PD_SM,
      alignItems: 'center',
      background: (hasToolbar && !this.props.titleHidden ? this.theme.palette.background.paper : null),
      borderBottom: (hasToolbar && !this.props.titleHidden ? DIVIDER : null)
    }}>
      {this.renderPrimaryButton()}
      {this.renderSecondaryButtons()}
      <Box style={{display: "flex", flexGrow: 1, alignItems: "center", overflowX: "scroll"}}>
        {this.props.toolbar}
      </Box>
      {this.props.buttons}
      {this.props.popovers}
      {this.props.actions
        ? <StyledBoxRow style={{paddingLeft: PD_SM, paddingRight: PD_SM}}>
          {this.props.actions.map(action => PageFragment.renderPageToolbarButtonEx(action))}
        </StyledBoxRow>
        : null}
      {this.renderMenuOptions(this.props.menuOptions)}
      {Boolean(this.props.onCloseDialog) ?
        <Button onClick={(event) => this.props.onCloseDialog()}>
          <CloseOutlined style={ICON_BUTTON_XSM_STYLE_PD}/>
        </Button>
        : null}
    </Box>;
  }

  private renderPrimaryButton() {
    if ((this.props.text || this.props.showBackButton) && !this.props.titleHidden) {
      let text = <Typography variant="body1"
                             style={{fontWeight: "bold", whiteSpace: "nowrap"}}>{this.props.showBackButton ? "Back" : this.props.text}</Typography>;
      const variant = undefined;//this.props.variant;
      switch (variant) {
        // case 'decorated':
        //   return <ButtonBase disabled={!this.props.showBackButton} onClick={() => this.props.onBackButtonClicked?.()}>
        //     <Card style={{
        //       display: "flex",
        //       flexDirection: "row",
        //       alignItems: "center",
        //       backgroundColor: colorYellow,
        //       padding: '12px 16px 12px 32px',
        //       margin: '0px 0px 0px -16px',
        //       boxShadow: CARD_SHADOW,
        //       clipPath: 'inset(-24px -24px -24px 16px)',
        //       color: "black",
        //     }}
        //           elevation={8}>
        //       {this.props.showBackButton ?
        //         <ArrowBack style={{...ICON_BUTTON_XSM_STYLE, marginRight: PD_XSM}}/>
        //         : null}
        //       {text}
        //     </Card>
        //   </ButtonBase>;
        // case 'subdecorated':
        //   return <ButtonBase disabled={!this.props.showBackButton} onClick={() => this.props.onBackButtonClicked?.()}>
        //     <Card style={{
        //       display: "flex",
        //       alignItems: "center",
        //       backgroundColor: white,
        //       padding: '12px 16px 12px 24px',
        //       margin: '0px 8px 0px -16px',
        //       boxShadow: CARD_SHADOW,
        //       clipPath: 'inset(-24px -24px -24px 16px)',
        //     }}>
        //       {this.props.showBackButton ?
        //         <ArrowBack style={{...ICON_BUTTON_XSM_STYLE, marginRight: PD_XSM}}/>
        //         : null}
        //       {text}
        //     </Card>
        //   </ButtonBase>;
        case 'none':
        case undefined:
        default:
          return <Box display='flex'
                      style={{alignSelf: "flex-end", paddingLeft: PD_SM, height: SZ_SM, alignItems: 'center'}}>
            <ButtonBase disabled={!this.props.showBackButton} onClick={() => this.props.onBackButtonClicked?.()}>
              {this.props.showBackButton ?
                  <ArrowBack style={{...ICON_BUTTON_XSM_STYLE, marginRight: PD_XSM}}/>
                : null}
              {text}
            </ButtonBase>
            {this.props.buttons}
          </Box>;
      }
    } else if (this.props.logo) {
      return <img src={this.props.logo} style={{height: 72}}/>;
    }
    return null;
  }

  private renderSecondaryButtons(): ReactElement {
    // TODO: Make this work with tabOptionGroup
    if (!this.props.tabOptions || (this.props.tabOptions.options.length <= 1 && !this.props.tabOptions.alwaysShow)) {
      return null;
    }
    return <Box style={{display: "flex", marginLeft: PD_SM, alignItems: "center", minHeight: SZ_SM, overflowX: "scroll", gap: PD_SM}} className="hidescroll">
      {this.props.tabOptions?.options.map(optionItem => {
        if (optionItem instanceof TabOptionItem) {
          return this.renderTabOptionItem(this.props.tabOptions, optionItem as TabOptionItem);
        } else if (optionItem instanceof TabOptionItemGroup) {
          return this.renderTabOptionItemGroup(this.props.tabOptions, optionItem as TabOptionItemGroup)
        }
      })}
    </Box>;
  }

  private renderTabOptionItem(tabOptions: TabOptions, optionItem: TabOptionItem) {
    return this.renderTabOptionItemButton(optionItem, () => {
      if (optionItem.onClicked?.(optionItem)) {
        return;
      }
      let selected = !optionItem.isSelected();
      optionItem.setSelected(selected);
      if (selected) {
        tabOptions.onTabSelected?.(optionItem.id);
      }
      this.forceUpdate();
    });
  }

  private renderTabOptionItemGroup(tabOptions: TabOptions, optionItemGroup: TabOptionItemGroup) {
    switch (optionItemGroup.layout) {
      case "collapse":
        let selectedItem = optionItemGroup.getSelectedItem();
        return <Button
          style={{
            background: Boolean(selectedItem.isSelected()) ? colorHighlightAlt : lightGray,
            paddingLeft: PD_SM,
            paddingRight: PD_SM,
            height: SZ_SM,
            textTransform: "none",
          }}
          onClick={() => {
            // Show popover
          }}>
          <Typography style={{
            overflow: "hidden",
            fontWeight: "bold",
            whiteSpace: "nowrap",
            maxWidth: SZ_XLG,
            textOverflow: "ellipsis",
          }}>{selectedItem.text}</Typography>
        </Button>;
      case "expand":
        return <StyledBoxRow>
          {optionItemGroup.items.map((item, index) => this.renderTabOptionItemButton(item, () => {
            let optionItem = optionItemGroup.items[index];
            if (optionItem.onClicked?.(item)) {
              return;
            }
            optionItemGroup.setSelectedIndex(index);
            tabOptions.onTabSelected?.(optionItem.id);
            this.forceUpdate();
          }))}
        </StyledBoxRow>
    }
  }

  private renderTabOptionItemButton(optionItem: TabOptionItem, onClick: () => void,) {
    return <Button
      style={{
        background: Boolean(optionItem.isSelected()) ? colorHighlightAlt : lightGray,
        paddingLeft: PD_SM,
        paddingRight: PD_SM,
        // height: SZ_SM,
        textTransform: "none",
      }}
      onClick={() => onClick()}>
      <Typography style={{
        overflow: "hidden",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        maxWidth: SZ_XXLG,
        textOverflow: "ellipsis",
      }}>{optionItem.text}</Typography>
    </Button>;
  }

  private onMenuOptionsButtonClicked(event) {
    this.setState({
      menuOptionsAnchorEl: event.target,
    });
  }

  private onMenuClosed() {
    this.setState({
      menuOptionsAnchorEl: null,
    });
  }

  private renderMenuOptions(menuOptions: MenuOption[]) {
    if (!menuOptions) {
      return null;
    }
    let menuOptionsAsItem: MenuOption[] = menuOptions.filter(value => ((value.flags & MenuOption.OPTIONS_ITEM_TYPE_MASK) >> 0) === 0);
    let menuOptionsAsButton: MenuOption[] = menuOptions.filter(value => ((value.flags & MenuOption.OPTIONS_ITEM_TYPE_MASK) >> 0) === MenuOption.OPTIONS_ITEM_TYPE_BUTTON_FLAG || ((value.flags & MenuOption.OPTIONS_ITEM_TYPE_MASK) >> 0) === MenuOption.OPTIONS_ITEM_TYPE_ACTION_FLAG);
    let menuOptionsAsText: MenuOption[] = menuOptions.filter(value => ((value.flags & MenuOption.OPTIONS_ITEM_TYPE_MASK) >> 0) === MenuOption.OPTIONS_ITEM_TYPE_TEXT_FLAG);
    return (
      <Box display="flex">
        {menuOptionsAsButton.map(menuOption => {
          let IconType = menuOption.iconType;
          return <Card style={{marginRight: PD_XSM}}>
            <ButtonBase style={{width: SZ_SM, height: SZ_SM}}
                        onClick={(event) => {
                          if (menuOption.onClick) {
                            menuOption.onClick(event);
                            return;
                          }
                          this.props.onMenuButtonClicked(menuOption);
                        }}>
              <IconType/>
            </ButtonBase>
          </Card>
        })}
        {menuOptionsAsItem.length > 0 ? <Card style={{marginRight: CARD_HORIZONTAL_PADDING}}>
            <ButtonBase style={{width: SZ_SM, height: SZ_SM}}
                        onClick={(event) => this.onMenuOptionsButtonClicked(event)}>
              <ExpandMore/>
            </ButtonBase>
          </Card>
          : null}
        {Boolean(this.state.menuOptionsAnchorEl) ? <Menu anchorEl={this.state.menuOptionsAnchorEl}
                                                         anchorOrigin={{
                                                           vertical: 'bottom',
                                                           horizontal: 'right',
                                                         }}
                                                         transformOrigin={{
                                                           vertical: 'top',
                                                           horizontal: 'right',
                                                         }}
                                                         open
                                                         keepMounted
                                                         onClose={() => this.onMenuClosed()}>
            {menuOptionsAsItem.map(menuOption => <MenuItem
              onClick={(event) => {
                this.onMenuClosed();
                this.props.onMenuButtonClicked(menuOption)
              }}>{menuOption.text}</MenuItem>)}
          </Menu>
          : null}
      </Box>
    );
  }

}
