import React from "react";
import {$KTS, KeyTextString} from "shared/types";
import {Autocomplete, Box, Button, CircularProgress, Divider, TextField, Typography} from "@mui/material";
import {PAGE_FRAGMENT_WIDTH, PD_SM, SZ_LG, SZ_XSM, SZ_XXLG} from "shared/dimens";

export type FilterPopoverContentProps = {}

type FilterPopoverContentState = {
  filters: number,
}

export class FilterPopoverContent extends React.Component<FilterPopoverContentProps, FilterPopoverContentState> {

  constructor(props: FilterPopoverContentProps, context: any) {
    super(props, context);
    this.state = {
      filters: 1,
    };
  }

  render() {
    return <>
      <Box style={{
        display: "flex",
        flexDirection: "column",
        padding: PD_SM,
        marginTop: -32,
        width: PAGE_FRAGMENT_WIDTH,
      }}>
        <Box style={{
          display: "flex",
          flexDirection: "column",
          maxHeight: 320,
          paddingTop: PD_SM,
          overflowY: "scroll",
          gap: PD_SM
        }}>
          {Array(this.state.filters).fill(0).map((val, index) => <>
              <Box style={{display: "flex", gap: PD_SM, alignItems: "center"}}>
                {index === 0 ? <Typography style={{fontWeight: "bold", width: SZ_LG, textAlign: "end"}}>Where</Typography>
                  : <FilterAutocomplete style={{width: SZ_LG}}
                                        displayName="Op"
                                        autocompleteLoader={() => new Promise(resolve => {
                                          resolve([$KTS("and", "And"), $KTS("or", "Or")]);
                                        })}/>
                }
                <FilterAutocomplete style={{flexGrow: 1}} displayName="Column"
                                    autocompleteLoader={() => new Promise(resolve => {
                                      resolve([$KTS("key", "Text")]);
                                    })}/>
                <FilterAutocomplete style={{width: SZ_XXLG}}
                                    displayName="Condition"
                                    disabled
                                    autocompleteLoader={() => new Promise(resolve => {
                                      resolve([$KTS("key", "Text")]);
                                    })}/>
              </Box>
              <FilterAutocomplete style={{marginLeft: 112}} displayName="Value"
                                  disabled
                                  autocompleteLoader={() => new Promise(resolve => {
                                    resolve([$KTS("key", "Text")]);
                                  })}/>
            </>
          )}
        </Box>
      </Box>
      <Divider/>
      <Box style={{display: "flex", padding: PD_SM, justifyContent: "flex-start"}}>
        <Button variant="text" onClick={() => this.setState({filters: this.state.filters + 1})}>+ Add
          another</Button>
      </Box>
      {/*<Divider/>*/}
      {/*<Box style={{display: "flex", padding: PD_SM, justifyContent: "flex-end"}}>*/}
      {/*  <StyledButton variant="contained">Done</StyledButton>*/}
      {/*</Box>*/}
    </>;
  }
}

function FilterAutocomplete(props: { style?: any, disabled?: boolean, displayName: string, autocompleteLoader: () => Promise<KeyTextString[]> }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<KeyTextString[]>([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const options = await props.autocompleteLoader();
      if (active) {
        setOptions([...options]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  return <Autocomplete
    style={props.style || {}}
    disabled={Boolean(props.disabled)}
    open={open}
    disableClearable
    onOpen={() => {
      setOpen(true);
    }}
    onClose={() => {
      setOpen(false);
    }}
    // value={props.source.getValue(props.contentItem)}
    // onChange={(event: any, newValue) => {
    //   props.source.setValue(props.contentItem, newValue.key);
    // }}
    //getOptionSelected={(option, value) => option.key === value.key}
    getOptionLabel={(option) => option.text}
    options={options}
    loading={loading}
    renderInput={(params) => (
      <TextField
        {...params}
        label={props.displayName}
        size="small"
        variant="outlined"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="inherit" size={SZ_XSM}/> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    )}
  />;
}
