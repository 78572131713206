import {KeyText} from "./types";

export type QuantityString = {
  zero?: string,
  one?: string,
  two?: string,
  few?: string,
  many?: string,
  other?: string,
}

export function I8N(str: string, key: string = str, description?: string) {
  return new I8NString(str, key, description);
}

export class I8NString {
  constructor(readonly str: string, readonly key: string = str, readonly description?: string) {
  }
}

export class StringUtil {

  static format(formatString: string, ...keyTexts: KeyText[]): string {
    let formatted = formatString;
    for (const keyText of keyTexts) {
      formatted = formatted.replaceAll("%{" + keyText.key + "}", keyText.text);
    }
    return formatted;
  }

  static toTitleCase(str: string) {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  }

  static px(px: number): string {
    return px + 'px';
  }

  static hash(key: any): number {
    let str: string;
    if (typeof key === "string") {
      str = key as string;
    } else {
      str = JSON.stringify(key);
    }
    let hash = 0, i, chr;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  static quantityString(quantity: number, string: QuantityString) {
    switch (quantity) {
      case 0:
        return string.zero || string.other;
      case 1:
        return string.one || string.other;
      case 2:
        return string.two || string.other;
    }
    return string.other;
  }

  static joinNotEmptyNull(separator: string, ...parts: string[]) {
    if (!parts) {
      return "";
    }
    return parts.filter(value => Boolean(value)).join(separator);
  }

  static cleanNonHTMLText(contentText: string): string {
    return contentText.replaceAll("<", "&lt;").replaceAll(">", "&gt;").replaceAll("\n", "<br/>");
  }

  private static readonly VALID_URL_SPECIAL_CHARS = "-._~:/?#[]@!$&'()*+,;%=";

  private static isValidUrlSpecialChar(ch: string): boolean {
    return ch.length == 1 && this.VALID_URL_SPECIAL_CHARS.indexOf(ch) >= 0;
  }

  static toGradientColors(representation: string): string[] {
    if (!representation?.startsWith("@gradient/")) {
      return [];
    }
    let colorStrings = representation.substring("@gradient/".length).split(",");
    let colors: string[] = [];
    for (let i = 0; i < colorStrings.length; i++) {
      let colorString = colorStrings[i];
      if (colorString.startsWith("#")) {
        if (colorString.startsWith("#ff")) {
          colorString = "#" + colorString.substring(3);
        }
        colors.push(colorString);
      }
    }
    return colors;
  }
}
