import {Box, Button, Card, Fab, List, ListItemIcon, ListItemText, SvgIcon, Typography} from "@mui/material";
import React, {ReactElement} from "react";
import {DIVIDER, PAGE_FRAGMENT_WIDTH, PD_MD, PD_SM, PD_XSM, SZ_MD} from "./dimens";
import {TitleBar} from "./TitleBar";
import {StyledBoxColumn, StyledEmpty, StyledListItem} from "./StyledComponents";
import {FabSpec} from "./PageFragment";
import {Action, EmptyConfig, MenuOption, TabOptionItem, TabOptions} from "./types";
import {PathComponent, PathProps} from "../index";
import {black, colorHighlightAlt, white} from "./colors";
import {Link} from "react-router-dom";
import {createInput} from "./BaseFragment";
import {BaseApp} from "./BaseApp";

export type SidebarItemsVariant = "default" | "comfortable" | "condensed" | "custom";

export type SidebarItemGroup = {
  items?: SidebarItem[],
  title?: string,
  variant?: SidebarItemsVariant,
  render?: () => ReactElement, // only used when variant is "custom"
}

export type SidebarItem = {
  path?: string,
  hidden?: boolean,
  onClick?: () => boolean,
  object?: any,
  title?: string,
  titleSecondary?: string,
  text?: string,
  textSecondary?: string,
  iconUrl?: string,
  iconFile?: string,
  iconType?: typeof SvgIcon,
  iconForegroundColor?: "white" | "black",
  iconBackgroundColor?: string,
  children?: SidebarItem[],
  accessoryButton?: ReactElement,
  style?: any,
  nestedPaths?: PathComponent[],
  render?: (pathProps: PathProps) => ReactElement, // only used when "path" is not null.
}

export type SidebarItems = {
  title?: string,
  groups: SidebarItemGroup[],
  containerId: string,
  variant?: SidebarItemsVariant,
}

export type SidebarTab = {
  id: string,
  title?: string,
  items: SidebarItems,
  action?: Action,
  selected?: SidebarItem,
  customViewType?: typeof React.Component,
  customViewTypeProps?: any,
  onItemsLoaded?: (items: SidebarItem[]) => number,
  onItemClicked?: (item: SidebarItem, index: number) => boolean, // true if click handled (selectedItem is not changed), false otherwise
  autoSelect?: boolean
  selectionDisabled?: boolean
  emptyConfig?: EmptyConfig,
  fabSpec?: FabSpec,
  menuOptions?: MenuOption[],
  showBackButton?: boolean,
  listener?: SidebarListener,
}

export type SidebarProps = {
  title?: string,
  styleFlags?: number,
  tabs: SidebarTab[],
}

export interface SidebarListener {
  onSelectionChanged(item: SidebarItem);

  onMenuButtonClicked(menuOption: MenuOption): boolean;
}

type SidebarState = {
  selectedTabId: string,
}

export class Sidebar extends React.Component<SidebarProps, SidebarState> {

  // Style flags
  private static readonly STYLE_ALWAYS_SHOW_TABS_MASK = 0x1;
  static readonly STYLE_ALWAYS_SHOW_TABS_FLAG = 1 << 0;

  constructor(props: SidebarProps, context: any) {
    super(props, context);
    this.state = {
      selectedTabId: props.tabs[0].id,
    };
  }

  private styleFlags() {
    return this.props.styleFlags || 0;
  }

  render() {
    const selectedTab = this.props.tabs.find(tab => tab.id === this.state.selectedTabId);
    let title = this.props.title || (this.props.tabs.length === 1 && this.props.tabs[0].title) || (selectedTab.showBackButton ? "Back" : null);
    const hasTitle = Boolean(title);
    return (
      <Box style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        height: "100%",
      }}>
        {hasTitle ? <TitleBar text={title} variant="subdecorated"
                              tabOptions={this.createToolbarTabOptions()}
                              menuOptions={selectedTab.menuOptions}
                              onMenuButtonClicked={(menuOption) => selectedTab.listener?.onMenuButtonClicked(menuOption)}
                              showBackButton={selectedTab.showBackButton}
                              onBackButtonClicked={() => this.onBackButtonClicked()}/>
          : null}
        {this.renderContent(selectedTab, hasTitle)}
        <span style={{height: 0, flexGrow: 1}}/>
        {this.renderActionPanel(selectedTab)}
        {this.renderFab(selectedTab.fabSpec)}
      </Box>
    );
  }

  private createToolbarTabOptions(): TabOptions {
    const tabOptions = new TabOptions(this.props.tabs.map(tab => {
      let tabOptionItem = new TabOptionItem(tab.id, tab.title);
      tabOptionItem.setSelected(tab.id === this.state.selectedTabId);
      return tabOptionItem;
    }), tabId => {
      this.setState({
        selectedTabId: tabId,
      });
    });
    if ((this.styleFlags() & Sidebar.STYLE_ALWAYS_SHOW_TABS_MASK) === Sidebar.STYLE_ALWAYS_SHOW_TABS_FLAG) {
      tabOptions.alwaysShow = true;
    }
    return tabOptions;
  }

  private renderActionPanel(selectedTab: SidebarTab): ReactElement {
    if (selectedTab.action) {
      return <Box style={{
        display: "flex",
        padding: PD_SM,
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        background: BaseApp.CONTEXT.getAppConfig().theme?.palette.background.paper,
        columnGap: PD_SM,
        borderTop: DIVIDER,
      }}>
        <Button
          style={{width: "100%", maxWidth: PAGE_FRAGMENT_WIDTH, margin: "auto"}}
          variant="outlined"
          disabled={selectedTab.action.disabled}
          onClick={(event) => selectedTab.action.onClick(event)}>
          {selectedTab.action.text}
        </Button>
      </Box>;
    }
    return null;
  }

  private renderContent(selectedTab: SidebarTab, hasTitle: boolean): ReactElement {
    const sidebarItems = selectedTab.items;
    return (sidebarItems.groups.length > 0 && (Boolean(sidebarItems.groups[0].render) || sidebarItems.groups[0].items?.length) > 0 ?
      <Box className="hidescroll"
           style={{width: "100%", paddingBottom: SZ_MD, overflowY: "scroll"}}>
        {this.renderSidebar(selectedTab, sidebarItems.groups, selectedTab.items.variant)}
      </Box>
      : this.renderEmpty(selectedTab));
  }

  private renderSidebar(selectedTab: SidebarTab, sidebarItemGroups: SidebarItemGroup[], variant: string) {
    return <Box style={{display: "flex", flexDirection: "column"}}>
      {sidebarItemGroups.map(group => {
        if (group.variant === "custom") {
          return group.render?.();
        }
        return this.renderSidebarItems(selectedTab, group.items, group.title, group.variant || variant);
      })}
    </Box>;
  }

  private renderSidebarItems(selectedTab: SidebarTab, sidebarItems: SidebarItem[], title: string, variant: string) {
    switch (variant) {
      case "condensed":
        return <StyledBoxColumn gutter>
          {this.renderSidebarItemsCondensed(selectedTab, sidebarItems, title)}
        </StyledBoxColumn>;
    }
    return this.renderSidebarItemsDefault(selectedTab, sidebarItems, title);
  }

  private renderSidebarItemsCondensed(selectedTab: SidebarTab, sidebarItems: SidebarItem[], title: string) {
    return Sidebar.renderSidebarItemsCondensedInternal(selectedTab, sidebarItems, title, this);
  }

  static renderSidebarItemsCondensed(selectedTab: SidebarTab, sidebarItems: SidebarItem[], title: string) {
    return this.renderSidebarItemsCondensedInternal(selectedTab, sidebarItems, title);
  }

  private static renderSidebarItemsCondensedInternal(selectedTab: SidebarTab, sidebarItems: SidebarItem[], title: string, sidebar?: Sidebar) {
    return <List>
      <Card style={{display: "flex", flexDirection: "column"}}>
        {title ? <Box style={{padding: PD_SM}}>
            <Typography variant="button" color={BaseApp.CONTEXT.getAppConfig().theme.palette.primary.main}><b>{title}</b></Typography>
          </Box>
          : null}
        {sidebarItems.map((item, index) => {
          if (item.hidden) {
            return null;
          }
          const IconType = item.iconType;
          const hasIcon = Boolean(IconType);
          const buttonContent = <>
            {hasIcon
              ? <ListItemIcon>
                <IconType/>
              </ListItemIcon>
              : null}
            <ListItemText primary={item.title} style={{color: black, textAlign: "start"}}/>
          </>;
          const buttonStyle = {
            paddingLeft: PD_SM,
            paddingRight: PD_SM,
            borderRadius: 0,
            background: selectedTab?.selected === item ? colorHighlightAlt : null,
          };
          return <Box style={{display: "flex", flexDirection: "column"}}>
            {/*{index > 0 ? <Divider style={{marginLeft: hasIcon ? PD_XXLG : 0}}/> : null}*/}
            {item.path
              ? <Button
                variant="text"
                to={item.path}
                component={Link}
                style={buttonStyle}>
                {buttonContent}
              </Button>
              : <Button
                variant="text"
                onClick={() => item.onClick?.() || sidebar?.handleListItemClick(selectedTab, sidebarItems, index)}
                style={buttonStyle}>
                {buttonContent}
              </Button>
            }
          </Box>;
        })}
      </Card>
    </List>;
  }

  static renderSidebarCustomCondensed(render: () => ReactElement, title: string) {
    return this.renderSidebarCustomCondensedInternal(render, title);
  }

  private static renderSidebarCustomCondensedInternal(render: () => ReactElement, title: string, sidebar?: Sidebar) {
    return <List>
      <Card style={{display: "flex", flexDirection: "column"}}>
        {title ? <Box style={{padding: PD_SM}}>
            <Typography variant="button" color={BaseApp.CONTEXT.getAppConfig().theme.palette.primary.main}><b>{title}</b></Typography>
          </Box>
          : null}
        {render()}
      </Card>
    </List>;
  }

  private renderSidebarItemsDefault(selectedTab: SidebarTab, sidebarItems: SidebarItem[], title: string) {
    return <Box style={{display: "flex", flexDirection: "column", padding: PD_SM, gap: PD_SM}}>
      {sidebarItems.map((item: SidebarItem, index) => {
        if (item.hidden) {
          return null;
        }
        return <StyledListItem path={item.path}
                               title={item.title}
                               titleSecondary={item.titleSecondary}
                               text={item.text}
                               textSecondary={item.textSecondary}
                               icon={item.iconType}
                               img={item.iconUrl}
                               iconForegroundColor={item.iconForegroundColor}
                               iconBackgroundColor={item.iconBackgroundColor}
                               selected={selectedTab.selected === item}
                               onClick={() => item.onClick?.() || this.handleListItemClick(selectedTab, sidebarItems, index)}/>;
      })}
    </Box>;
  }

  private renderEmpty(selectedTab: SidebarTab) {
    let emptyConfig = selectedTab.emptyConfig;
    if (!emptyConfig) {
      return null;
    }
    return <StyledEmpty emptyConfig={emptyConfig}/>;
  }

  private renderFab(fabSpec: FabSpec): ReactElement {
    if (fabSpec) {
      let IconType = fabSpec.iconType;
      let input = createInput(fabSpec.inputSpec);
      return <Fab variant={fabSpec.variant} color="secondary"
                  style={{position: 'absolute', right: PD_SM, bottom: PD_MD}}>
        {input}
        <IconType onClick={fabSpec.onClick}/>
        {fabSpec.variant === 'extended' ? <Typography variant="caption" style={{
          marginLeft: PD_XSM,
          marginRight: PD_XSM,
          fontWeight: "bold"
        }}>{fabSpec.text}</Typography> : null}
      </Fab>
    }
    return null;
  }

  private onBackButtonClicked() {
    // if (selectedTab.showBackButton) {
    //   selectedTab.page.popFragment();
    //   this.setState({
    //     selectedIndex: null,
    //   });
    // }
  }

  private handleListItemClick(selectedTab: SidebarTab, items: SidebarItem[], index: number) {
    if ((selectedTab.onItemClicked && !selectedTab.onItemClicked(items[index], index)) || (!selectedTab.onItemClicked && !selectedTab.selectionDisabled)) {
      selectedTab.listener?.onSelectionChanged(items[index]);
    }
  }
}
