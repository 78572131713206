import {Post} from "@/cmsblog-elements/types";
import {StyledBoxColumn, StyledBoxRow, StyledContainer} from "@/shared-site/StyledComponents";
import {BORDER_RADIUS, DIVIDER_COLOR, PD_LG} from "@/shared-site/dimens";
import {Typography} from "@mui/material";
import {HeadingVariant, headingVariant} from "@/shared-site/utils";
import {lightGray, mediumGray} from "@/shared-site/colors";
import Markdown from "react-markdown";
import {DateUtil} from "@/shared-site/date_util";

export function Post1(props: { post: Post }) {
  return <StyledContainer size="md" style={{gap: PD_LG}}>
    <Typography variant="body2" style={{color: mediumGray, fontWeight: "bold", textTransform: "uppercase"}}>{props.post.category}</Typography>
    <Typography variant={headingVariant(3, 4)}>{props.post.title}</Typography>
    {props.post.subtitle ? <HeadingVariant
        default_={6}
        style={{
          color: mediumGray,
          fontWeight: "normal"
        }}>
        {props.post.subtitle}
      </HeadingVariant>
      : null}
    <div style={{background: DIVIDER_COLOR, height: 1}}/>
    <StyledBoxRow>
      {/*<img src="/images/default_user.png" style={{width: 48, height: 48}}/>*/}
      <StyledBoxColumn style={{gap: 0}}>
        {/*<Typography variant="body2">{props.post.creator}</Typography>*/}
        <Typography variant="body2">{DateUtil.formatDateTime(props.post.published || props.post.created)}</Typography>
      </StyledBoxColumn>
    </StyledBoxRow>
    <div style={{background: DIVIDER_COLOR, height: 1}}/>
    <img src={props.post.coverImageUrl}
         style={{
           background: lightGray,
           width: "120%",
           marginLeft: "-10%",
           aspectRatio: 1.5,
           borderRadius: BORDER_RADIUS,
           objectFit: "cover"
         }}/>
    <Typography component="div">
      <Markdown>{props.post.body}</Markdown>
    </Typography>
  </StyledContainer>
}
