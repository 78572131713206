import {SvgIcon} from "@mui/material";
import {JsonObject} from "@/shared-site/json/json-object";
import {JsonProperty} from "@/shared-site/json/json-property";
import {dbRef_getVal} from "@/shared-site/database";
import {createSiteConfig} from "@/site/config";
import {initializeApp} from "firebase/app";
import {FirebaseOptions} from "@firebase/app";

export abstract class BaseKeyText<T> {

  constructor(readonly key: T, readonly text: string) {
  }
}

export class KeyText extends BaseKeyText<any> {

  constructor(key: any, text: string) {
    super(key, text);
  }
}

export class KeyTextNumber extends BaseKeyText<Number> {

  constructor(key: number, text: string) {
    super(key, text);
  }
}

export class KeyTextString extends BaseKeyText<string> {

  constructor(key: string, text: string) {
    super(key, text);
  }
}

export function $KTS(key: string, text: string) {
  return new KeyTextString(key, text);
}

export function DisplayName(firstname?: string, lastname?: string) {
  let displayName = firstname?.length > 0 ? firstname : "";
  displayName += lastname?.length > 0 ? ((displayName.length > 0 ? " " : "") + lastname) : "";
  return displayName;
}

export type EmptyConfig = {
  iconType?: typeof SvgIcon,
  iconify?: string,
  title: string,
  text?: string,
  action?: Action,
  altAction?: Action,
}

export class Action {

  tag?: any;
  destructive?: boolean;
  variant?: "text" | "contained" | "outlined";

  isSelected?: () => boolean;

  constructor(readonly text: string, readonly onClick?: (event?: any, ...args: any[]) => void, readonly iconType?: typeof SvgIcon, readonly iconUrl?: string, readonly iconify?: string, readonly disabled?: boolean) {
  }

  makeDestructive(): Action {
    this.destructive = true;
    return this;
  }
}

export class MenuOption {
  static readonly OPTIONS_ITEM_TYPE_MASK = 0x3;
  static readonly OPTIONS_ITEM_TYPE_BUTTON_FLAG = 1;
  static readonly OPTIONS_ITEM_TYPE_TEXT_FLAG = 2;
  static readonly OPTIONS_ITEM_TYPE_ACTION_FLAG = 3;

  static createFromAction(action: Action) {
    return new MenuOption(null, action.text, action.iconType, MenuOption.OPTIONS_ITEM_TYPE_BUTTON_FLAG, action.onClick);
  }

  constructor(readonly id: string, readonly text: string, readonly iconType: typeof SvgIcon = null, readonly flags: number = 0, readonly onClick?: (event) => void) {
  }
}

export type SiteConfig = {
  provisioningId?: string,
  provisionedAppId?: string,
  firebaseConfig?: FirebaseOptions,
}

export class SiteConfigProvider {

  private static instance: SiteConfigProvider;

  static getInstance(): SiteConfigProvider {
    if (!this.instance) {
      this.instance = new SiteConfigProvider();
    }
    return this.instance;
  }

  private readonly siteConfig: SiteConfig;

  private constructor() {
    this.siteConfig = createSiteConfig();
    if (this.siteConfig.firebaseConfig) {
      initializeApp(this.siteConfig.firebaseConfig);
    }
  }

  get(): SiteConfig {
    return this.siteConfig;
  }
}

@JsonObject()
export abstract class BaseListItem {

  @JsonProperty()
  id: string;

  @JsonProperty()
  creator: string;

  @JsonProperty()
  created: number;

  protected constructor(id: string, creator?: string, created?: number) {
    this.id = id;
    this.creator = creator;
    this.created = created;
  }

  async onAfterItemDeserialized(): Promise<void> {
  }
}

export abstract class AbstractListItemsLoader<T extends BaseListItem> {

  protected abstract basePath(): string;

  protected abstract deserializeItem(value: any): T;

  protected abstract sortOrder(item1: T, item2: T): number;

  abstract loadListItems(): Promise<void>;

  abstract getOrLoadListItems(): Promise<T[]>;

  abstract getListItems(): T[];
}

export abstract class BaseListItemsLoader<T extends BaseListItem> extends AbstractListItemsLoader<T> {

  private readonly items = new Map<string, T>();
  private sortedItems: T[];

  private itemsLoaded: boolean = false;

  protected abstract basePath(): string;

  protected abstract deserializeItem(value: any): T;

  protected async onAfterItemDeserialized(item: T): Promise<T> {
    await item.onAfterItemDeserialized();
    return item;
  }

  protected abstract sortOrder(item1: T, item2: T): number;

  private listPath(): string {
    const basePath = this.basePath();
    let contextScope: string = "";
    const provisionedAppId = SiteConfigProvider.getInstance().get().provisionedAppId;
    if (provisionedAppId) {
      contextScope = "apps/" + provisionedAppId + "/";
    }
    return contextScope + basePath;
  }

  async loadListItems(): Promise<void> {
    const path = this.listPath();
    const val = await dbRef_getVal(path);
    if (val) {
      const awaitAll: Promise<T>[] = [];
      for (const key in val) {
        let value = val[key];
        let item = this.deserializeItem(value);
        awaitAll.push(this.onAfterItemDeserialized(item));
      }
      const items = await Promise.all(awaitAll);
      for (const item of items) {
        this.items.set(item.id, item);
      }
    }
    this.itemsLoaded = true;
    return Promise.resolve();
  }

  async getOrLoadListItems(): Promise<T[]> {
    if (!this.sortedItems) {
      await this.loadListItems();
    }
    return Promise.resolve(this.getListItems());
  }

  getListItems(): T[] {
    if (!this.itemsLoaded) {
      console.error("Getting items but they're not loaded yet.");
    }
    if (!this.sortedItems) {
      this.sortedItems = [...this.items.values()].sort(this.sortOrder);
    }
    return this.sortedItems;
  }
}
