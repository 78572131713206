import {md5} from "@/shared-site/md5";
import {SiteConfig} from "@/shared-site/types";
import {CMSBLOG_ADMIN_FIREBASE_CONFIG} from "@/cmsshared-admin/BaseCmsAdminApp";

export function createSiteConfig(): SiteConfig {
  return {
    provisioningId: md5("FYNEAPPS"),
    provisionedAppId: "cmsblog-admin",
    firebaseConfig: CMSBLOG_ADMIN_FIREBASE_CONFIG,
  }
}