import {PageFragment, PageFragmentProps, PageFragmentState} from "./PageFragment";
import {Action, BaseListItem, BaseListItemsLoader, OnListItemsListener} from "./types";
import {DownloadOutlined, FilterListOutlined, PrintOutlined} from "@mui/icons-material";
import {TableDataSource} from "./TableDataContainer";
import React, {ReactElement} from "react";
import {FilterPopoverContent} from "./FilterPopoverContent";
// import {jsPDF} from "jspdf";

export type TableDataSourceFragmentProps = PageFragmentProps & {}

export type TableDataSourceFragmentState = PageFragmentState & {
  filterPopoverAnchorEl?: any,
}

//@ts-ignore
export abstract class TableDataSourceFragment<T, D, P extends TableDataSourceFragmentProps = TableDataSourceFragmentProps, S extends TableDataSourceFragmentState = TableDataSourceFragmentState> extends PageFragment<P, S>
  implements TableDataSource<T, D> {

  createToolbarActions(): Action[] {
    return [
      new Action("Filter", (event) => {
        this.setState({filterPopoverAnchorEl: event.target});
      }, FilterListOutlined),
      new Action("Download", () => this.doDownload(), DownloadOutlined),
      new Action("Print", () => this.doPrint(), PrintOutlined),
    ];
  }

  createToolbarPopovers(): ReactElement[] {
    return [
      this.renderPopover("Filter", this.state.filterPopoverAnchorEl, <FilterPopoverContent/>, () => {
        this.setState({filterPopoverAnchorEl: null})
      })
    ]
  }

  protected toHtmlTableString(): string {
    // const data = this.
    return "<table>" +
      "<thead>" +
      "<tr>" +
      "<td>Hello world</td>" +
      // "<td>{%text%}</td>".replace("{%text%}", ) +
      "</tr>" +
      "</thead>"
    // return null;
  }

  private doDownload() {
    // const htmlTable = this.toHtmlTableString();
    // if (!htmlTable) {
    //   return null;
    // }
    // const doc = new jsPDF();
    // doc.html(htmlTable, {callback: doc => doc.save("hello.pdf")});
  }

  private doPrint() {

  }
}

export abstract class ListTableDataSourceFragment<T extends BaseListItem, L extends BaseListItemsLoader<T>, D, P extends TableDataSourceFragmentProps = TableDataSourceFragmentProps, S extends TableDataSourceFragmentState = TableDataSourceFragmentState> extends TableDataSourceFragment<T, D, P, S> implements OnListItemsListener<T> {

  componentDidMount() {
    super.componentDidMount();
    this.getLoader().registerObserver(this);
  }

  componentWillUnmount() {
    this.getLoader().unregisterObserver(this);
  }

  onItemChanged(item: T) {
    this.forceUpdate();
  }

  protected async fetchOnMount(): Promise<void> {
    await this.getLoader().loadListItems();
  }

  protected abstract getLoader(): L;
}