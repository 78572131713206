import React, {useEffect, useState} from "react";
import {Box, CircularProgress} from "@mui/material";
import {DW_XL, PD_MD} from "./dimens";
import Typography from "@mui/material/Typography";

export function HtmlFragment(props: { url: string }) {
  const [html, setHtml] = useState(null);
  useEffect(() => {
    fetch(props.url).then(response => response.text()).then(text => {
      setHtml(text)
    });
  }, []);
  return html ? <Box style={{
      maxWidth: DW_XL,
      padding: PD_MD,
    }}><Typography dangerouslySetInnerHTML={{__html: html}}/>
    </Box>
    : <Box style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <CircularProgress/>
    </Box>;
}
