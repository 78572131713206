import {AppConfig} from "shared/BaseApp";
import React, {ReactElement} from 'react';
import {createTheme, ThemeOptions} from "@mui/material";
import {DEFAULT_THEME_OPTIONS} from "../shared/colors";
import {AppPrefListener, AppPrefs} from "./app_prefs";
import {Main} from "./Main";
import {PathComponent} from "../index";
import {ProvisionedApp} from "../shared/ProvisionedApp";
import {SiteConfigProvider} from "@/shared-site/types";
import {ProvisionedAppConfig} from "@/shared/types";
import {StyledBoxRow, StyledSpan} from "@/shared/StyledComponents";
import fyneappsStamp from "cmsshared-admin/res/images/fyneapps_stamp.png";
import {PD_SM} from "@/shared/dimens";

export const THEME_COLOR_PRIMARY = "#00272d";
export const THEME_COLOR_PRIMARY_CONTRAST = "#fff";
export const THEME_COLOR_SECONDARY = "#fff";
export const THEME_COLOR_SECONDARY_CONTRAST = "#000";

export const THEME_OPTIONS: ThemeOptions = {
  ...DEFAULT_THEME_OPTIONS,
  palette: {
    primary: {
      main: THEME_COLOR_PRIMARY,
      contrastText: THEME_COLOR_PRIMARY_CONTRAST,
    },
    secondary: {
      main: THEME_COLOR_SECONDARY,
      contrastText: THEME_COLOR_SECONDARY_CONTRAST,
    },
  }
};

export const THEME = createTheme(THEME_OPTIONS);

export const CMSBLOG_ADMIN_FIREBASE_CONFIG = {
  apiKey: "AIzaSyCW9C2DldUmqckgO1JRqilUJ7I64YI4MXU",
  authDomain: "fyneapps-shared.firebaseapp.com",
  databaseURL: "https://fyneapps-shared-default-rtdb.firebaseio.com",
  projectId: "fyneapps-shared",
  storageBucket: "fyneapps-shared.appspot.com",
  messagingSenderId: "1008652909857",
  appId: "1:1008652909857:web:0d00dd4cdea364cc619260",
  measurementId: "G-0DM2XPEDBB"
};

export abstract class BaseCmsAdminApp extends ProvisionedApp implements AppPrefListener {

  protected readonly appPrefs = AppPrefs.getInstance();

  onCreateAppConfig(): AppConfig {
    const siteConfig = SiteConfigProvider.getInstance().get();
    return {
      name: "cmsadmin",
      icon: "/icon.png",
      logo: "/logo192.png",
      stamp: "@icon/assignment",
      defaultUserImage: "/images/default_user.png",
      theme: THEME,
      firebaseConfig: {
        options: CMSBLOG_ADMIN_FIREBASE_CONFIG,
      },
      loginConfig: {
        provisioning: {
          provisioningId: siteConfig.provisioningId,
        },
        uiProps: {
          topDecorator: this.renderLoginBottomDecorator(),
        },
      },
    };
  }

  private renderLoginBottomDecorator(): ReactElement {
    return <StyledBoxRow style={{padding: PD_SM}}>
      <StyledSpan/>
      <img src={fyneappsStamp} style={{height: 48, opacity: 0.33}}/>
      <StyledSpan/>
    </StyledBoxRow>;
  }

  getProvisionedAppId(): string {
    const siteConfig = SiteConfigProvider.getInstance().get();
    return siteConfig.provisionedAppId;
  }

  getProvisionedAppConfig(): ProvisionedAppConfig {
    const siteConfig = SiteConfigProvider.getInstance().get();
    return {
      provisioningId: siteConfig.provisioningId,
    };
  }

  getCollapseSidebar(): boolean {
    return this.appPrefs.getCollapseSidebar();
  }

  setCollapseSidebar(collapse: boolean) {
    this.appPrefs.setCollapseSidebar(collapse);
  }

  componentDidMount() {
    super.componentDidMount();
    this.appPrefs.addListener(AppPrefs.PREF_COLLAPSE_SIDEBAR, this);
  }

  componentWillUnmount() {
    this.appPrefs.removeListener(AppPrefs.PREF_COLLAPSE_SIDEBAR, this);
  }

  onPrefChanged(key: string) {
    switch (key) {
      case AppPrefs.PREF_COLLAPSE_SIDEBAR:
        this.notifyAppEvent("collapse_sidebar");
        break;
    }
  }

  static nestedPaths(): PathComponent[] {
    return Main.nestedPaths();
  }

  protected renderMain(): ReactElement {
    return <>
      <Main path={this.props.path}/>
    </>;
  }
}
