import {User} from "shared/entities";
import {Prefs} from "../shared/prefs";
import {md5} from "../shared/md5";
import {v4 as uuid} from "uuid";

export interface AppPrefListener {
  onPrefChanged(key: string);
}

export class AppPrefs {

  static readonly PREF_DEVICE_ID = "device_id";
  static readonly PREF_PROVISIONING_ID = "provisioning_id";
  static readonly PREF_COLLAPSE_SIDEBAR = "collapse_sidebar";
  static readonly PREF_PLUGIN_URLS = "plugin_urls";

  private readonly listenersMap = new Map<string, Array<WeakRef<AppPrefListener>>>();

  private static instance: AppPrefs;

  static getInstance() {
    if (!this.instance) {
      this.instance = new AppPrefs();
    }
    return this.instance;
  }

  private readonly prefs = new Prefs();
  private user?: User;

  private constructor() {
  }

  addListener(key: string, listener: AppPrefListener) {
    let listeners = this.listenersMap.get(key);
    if (!listeners) {
      listeners = [];
      this.listenersMap.set(key, listeners);
    }
    listeners.push(new WeakRef(listener));
  }

  removeListener(key: string, listener: AppPrefListener) {
    let listeners = this.listenersMap.get(key);
    if (listeners) {
      const index = listeners.findIndex(ref => ref.deref() === listener);
      if (index >= 0) {
        listeners.splice(index, 1);
      }
    }
  }

  clear() {
    this.prefs.clear();
  }

  isDev(): boolean {
    return false;
  }

  hasAuthCredentials(): boolean {
    return this.getProvisioningId()?.length > 0;
  }

  setAuthCredentials(provisioningId: string) {
    this.prefs.setString(AppPrefs.PREF_PROVISIONING_ID, provisioningId);
  }

  clearAuthCredentials() {
    this.prefs.remove(AppPrefs.PREF_PROVISIONING_ID);
  }

  getDeviceId(): string {
    let deviceId = this.prefs.getString(AppPrefs.PREF_DEVICE_ID);
    if (!deviceId) {
      deviceId = md5(uuid());
      this.prefs.setString(AppPrefs.PREF_DEVICE_ID, deviceId);
    }
    return deviceId;
  }

  getProvisioningId(): string {
    return this.prefs.getString(AppPrefs.PREF_PROVISIONING_ID);
  }

  getCollapseSidebar() {
    return this.prefs.getBoolean(AppPrefs.PREF_COLLAPSE_SIDEBAR);
  }

  setCollapseSidebar(collapse: boolean) {
    this.prefs.setBoolean(AppPrefs.PREF_COLLAPSE_SIDEBAR, collapse);
    this.notify(AppPrefs.PREF_COLLAPSE_SIDEBAR);
  }

  getPluginUrls(): string[] {
    const string = this.prefs.getString(AppPrefs.PREF_PLUGIN_URLS);
    if (!string) {
      return [];
    }
    const urls: string[] = JSON.parse(string);
    return urls;
  }

  hasPluginUrl(url: string) {
    const urls: string[] = this.getPluginUrls();
    const index = urls.findIndex(u => u === url);
    return index >= 0;
  }

  addPluginUrl(url: string) {
    const urls: string[] = this.getPluginUrls();
    const index = urls.findIndex(u => u === url);
    if (index < 0) {
      urls.push(url);
      this.prefs.setString(AppPrefs.PREF_PLUGIN_URLS, JSON.stringify(urls));
    }
  }

  removePluginUrl(url: string) {
    const urls: string[] = this.getPluginUrls();
    const index = urls.findIndex(u => u === url);
    if (index >= 0) {
      urls.splice(index, 1);
      this.prefs.setString(AppPrefs.PREF_PLUGIN_URLS, JSON.stringify(urls));
    }
  }

  private notify(key: string) {
    const listeners = this.listenersMap.get(key);
    listeners?.forEach(ref => ref.deref()?.onPrefChanged(key));
  }
}
