/* eslint-disable */
import {Typography, useMediaQuery, useTheme} from "@mui/material";

const VARIANTS = ["h1", "h2", "h3", "h4", "h5", "h6", "body1", "body2", "caption"];

// Deprecated. Do not use.
// Use <Heading ...> instead.
export function headingVariant(default_: number, max: number = VARIANTS.length): "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "body1" | "body2" | "caption" {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  let n: number;
  if (lg) {
    n = default_;
  } else if (sm) {
    n = default_ + 1;
  } else {
    n = default_ + 2;
  }
  if (!n || n > VARIANTS.length) {
    n = 7;
  } else if (n < 1) {
    n = 1;
  }
  n = Math.min(max, n);
  //@ts-ignore
  return VARIANTS[n - 1];
}

export function HeadingVariant(props: { default_: number, max?: number, children?: any, style?: any }) {
  return <Typography variant={headingVariant(props.default_, props.max)}
                     style={props.style}>{props.children}</Typography>;
}