import {getDownloadURL, getStorage, ref, StorageReference, uploadBytes} from "@firebase/storage";
import {MimeTypeMap} from "./mime_types";
import {v4 as uuid} from "uuid"
import {getProvisioningId} from "./BaseApp";

// These are excluded from provisioning id prefix.
const GLOBAL_PATHS = []

export function storageRef(path?: string): StorageReference {
  if (!path) {
    path = "";
  }
  if (!path.startsWith("/")) {
    path = "/" + path;
  }
  let prefix = "";
  if (GLOBAL_PATHS.findIndex(globalPath => path.startsWith("/" + globalPath + "/")) < 0) {
    const provisioningId = getProvisioningId();
    prefix = provisioningId ? "/stored/" + provisioningId + "/" : "";
  }
  const storage = getStorage();
  return ref(storage, prefix + path);
}

export function ab2str(buf) {
  return String.fromCharCode.apply(null, new Uint16Array(buf));
}

export function str2ab(str: string): ArrayBuffer {
  const buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
  const bufView = new Uint16Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

export async function storageRef_createFile(mimeType: string, storedDataSource: string, storedDataPath?: string): Promise<string> {
  if (!storedDataPath) {
    storedDataPath = uuid() + "." + MimeTypeMap.getInstance().getExtensionFromMimeType(mimeType);
  }
  const fileRef = storageRef(storedDataSource + "/" + storedDataPath);
  await uploadBytes(fileRef, new Uint8Array(0));
  return storedDataPath;
}

export async function storageRef_getFileContentString(storedDataSource: string, storedDataPath: string): Promise<string> {
  const fileRef = storageRef(storedDataSource + "/" + storedDataPath);
  return fetch(await getDownloadURL(fileRef)).then(resp => resp.text());
}

export async function storageRef_setFileContentString(storedDataSource: string, storedDataPath: string, contentString: string): Promise<void> {
  const fileRef = storageRef(storedDataSource + "/" + storedDataPath);
  await uploadBytes(fileRef, str2ab(contentString));
}
