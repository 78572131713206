import {FabSpec, PageFragment, PageFragmentProps, PageFragmentState} from "../../shared/PageFragment";
import React, {ReactElement} from "react";
import {PageWithSidebarContainer, PageWithSidebarContainerRenderer} from "../../shared/PageWithSidebarContainer";
import {Sidebar, SidebarItem, SidebarListener} from "../../shared/Sidebar";
import {Collection, CollectionsRegistry} from "./types";
import {Add, ArticleOutlined} from "@mui/icons-material";
import {EditContentHelper} from "./BaseEditContentFragment";

export type ContentFragmentProps = PageFragmentProps & {}

type ContentFragmentState = PageFragmentState & {
  collections: Collection<any, any>[],
  sidebarItems: SidebarItem[],
  selectedContentType: string,
}

export class ContentFragment extends PageFragment<ContentFragmentProps, ContentFragmentState> implements SidebarListener, PageWithSidebarContainerRenderer {

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    console.log(CollectionsRegistry.getInstance().get().length);
    this.setState({
      collections: CollectionsRegistry.getInstance().get(),
    });
  }

  createFabSpec(): FabSpec {
    const collection = this.getSelectedSidebarItem().object as Collection<any, any>;
    return {
      iconType: Add,
      variant: 'extended',
      text: "Add new",
      onClick: () => EditContentHelper.addContent(collection),
    }
  }

  private static sidebarItemType(sidebarItem: SidebarItem) {
    return (sidebarItem.object as Collection<any, any>).loader.type;
  }

  componentDidUpdate(prevProps: Readonly<ContentFragmentProps>, prevState: Readonly<ContentFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (this.state.collections !== prevState.collections) {
      const collectionItems = this.state.collections.map(collection => {
        return {
          title: collection.displayName,
          iconType: ArticleOutlined,
          object: collection,
        } as SidebarItem;
      });
      const sidebarItems = [...collectionItems];
      this.setState({
        sidebarItems: sidebarItems,
        selectedContentType: sidebarItems?.length > 0 ? ContentFragment.sidebarItemType(sidebarItems[0]) : null,
      });
    }
  }

  onSelectionChanged(item: SidebarItem) {
    this.setState({
      selectedContentType: (item.object as Collection<any, any>).loader.type,
    });
  }

  renderContent(): React.ReactElement {
    return <PageWithSidebarContainer renderer={this}/>;
  }

  private getSelectedSidebarItem(): SidebarItem {
    return this.state.sidebarItems?.find(item => ContentFragment.sidebarItemType(item) === this.state.selectedContentType);
  }

  renderPageWithSidebarContainerContent(): ReactElement {
    const selected = this.getSelectedSidebarItem();
    const collection = selected.object as Collection<any, any>;
    const ContentTableFragmentType = collection.ContentTableFragmentType;
    return <ContentTableFragmentType collection={collection}/>;
  }

  renderPageWithSidebarContainerSidebar(): React.ReactElement {
    return <Sidebar tabs={[{
      id: "default",
      selected: this.getSelectedSidebarItem(),
      items: {
        groups: [
          {
            items: this.state.sidebarItems,
            title: "Collections",
            variant: "condensed",
          },
        ],
        containerId: "content",
      },
      listener: this,
    }]}/>;
  }
}