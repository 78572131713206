import React from "react";
import {Box, Button, ButtonBase, Card, Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {v4 as uuid} from "uuid";
import {DIVIDER, PD_SM, SZ_MD, SZ_SM, SZ_SSM} from "./dimens";
import {TableDataMetadata, tableDataMetadataKey} from "./tabledata";
import {SettingsOutlined} from "@mui/icons-material";
import {BaseApp} from "./BaseApp";
import {Action, CreateActions} from "./types";

export type DataTableRowProps<T, D> = {
  data: T,
  row: D,
  contentItemsMap: Map<string, TableDataContentItem>,
  disableSelection?: boolean,
  onTableCellChanged?: (data: T, cellId: string, value: any) => void,
  onTableDataSelected: (data: T) => void,
}

type DataTableRowState = {}

class DataTableRowView<T, D extends TableDataRow> extends React.Component<DataTableRowProps<T, D>, DataTableRowState> {

  constructor(props: DataTableRowProps<T, D>, context: any) {
    super(props, context);
  }

  render() {
    const data = this.props.data;
    const row = this.props.row;
    return <>
      <TableRow hover style={{position: "relative"}}>
        {Object.getOwnPropertyNames(row).map(value => {
          const cell = row[value];
          const contentItem = this.props.contentItemsMap.get(value);
           if (contentItem.metadata.type === "profile_photo") {
            return <TableCell style={{width: 0, paddingTop: 0, paddingBottom: 0}}><Card
              style={{width: SZ_SM, height: SZ_SM, flexShrink: 0}}>
              <img src={cell || BaseApp.CONTEXT.getAppConfig().defaultUserImage} style={{width: "100%", height: "100%"}}/>
            </Card></TableCell>;
          } else if (contentItem.metadata.type === "checkbox") {
            return <TableCell style={{width: SZ_MD, flexGrow: 0, flexShrink: 0}}>
              <Checkbox checked={cell} disabled={contentItem.metadata.disabled} onChange={(event, checked) => {
                row[value] = checked;
                this.forceUpdate();
                this.props.onTableCellChanged?.(data, contentItem.metadata.cellId, checked);
              }}/>
            </TableCell>;
          } else if (contentItem.metadata.type === "color") {
            return <TableCell style={{width: SZ_MD, flexGrow: 0, flexShrink: 0}}>
              <Button disabled style={{width: SZ_SM, height: SZ_SSM, backgroundColor: cell}}/>
            </TableCell>;
          } else if (contentItem.metadata.type === "actions") {
            const IconType = contentItem.metadata.actionsIcon || SettingsOutlined;
            return <TableCell style={{width: SZ_MD, flexGrow: 0, flexShrink: 0}}>
              <Button disabled={contentItem.metadata.disabled} onClick={(event) => {
                let actions: Action[];
                if (Array.isArray(contentItem.metadata.actions)) {
                  actions = contentItem.metadata.actions as Action[];
                } else {
                  actions = (contentItem.metadata.actions as CreateActions)(event, data);
                }
                BaseApp.CONTEXT.showActionsDialog("Actions", actions, data);
              }}>
                {<IconType/>}
              </Button>
            </TableCell>;
          } else if (contentItem.metadata.type === "custom") {
            return <TableCell style={contentItem.metadata.cellStyle}>{cell}</TableCell>;
          } else {
            let text;
            if (typeof cell === "string") {
              text = cell as string;
            } else if (cell instanceof Date) {
              const date = cell as Date;
              text = date.toISOString();
            } else {
              text = cell?.toString() || "";
            }
            return <TableCell style={{height: SZ_SSM, overflowX: "hidden", textOverflow: "ellipsis", ...contentItem.metadata.cellStyle}}>{text}</TableCell>;
          }
        })}
        {this.props.disableSelection
          ? null
          : <ButtonBase
            style={{position: "absolute", width: "100%", top: 0, left: 0, bottom: 0, right: 0, borderTop: DIVIDER}}
            onClick={() => this.props.onTableDataSelected(data)}/>
        }
      </TableRow>
    </>;
  }
}

// Marker interface for table data row objects
// A "style" field in subclasses will be applied to row view.
export interface TableDataRow {
}

export type TableDataSource<T, D extends TableDataRow> = {

  createTableDataRow(): D,

  applyTableDataToRow(data: T, row: D): void,

  onTableDataSelected(data: T);

  onTableCellChanged?: (data: T, cellId: string, value: any) => void;
}

export type TableDataContainerProps<T, D> = {
  data: T[],
  source: TableDataSource<T, D>,
  disableSelection?: boolean,
}

type TableDataContentItem = {
  id: string,
  target: any,
  metadata: TableDataMetadata,
}

type TableDataContainerState<D> = {
  contentItems: TableDataContentItem[],
  contentRows: Array<D>,
}

export class TableDataContainer<T, D> extends React.Component<TableDataContainerProps<T, D>, TableDataContainerState<D>> {

  private readonly contentItemsMap = new Map<string, TableDataContentItem>();

  constructor(props: TableDataContainerProps<T, D>, context: any) {
    super(props, context);
    this.state = {
      contentItems: [],
      contentRows: [],
    }
  }

  componentDidMount() {
    this.updateContent();
  }

  componentDidUpdate(prevProps: Readonly<TableDataContainerProps<T, D>>, prevState: Readonly<TableDataContainerState<D>>, snapshot?: any) {
    if (prevProps.data !== this.props.data || prevProps.source !== this.props.source) {
      this.updateContent();
    }
  }

  private updateContent() {
    const row = this.props.source.createTableDataRow();
    const contentItems = this.findContentItems(row);
    contentItems.forEach(contentItem => this.contentItemsMap.set(contentItem.id, contentItem));
    let contentRows = [];
    this.props.data.forEach(item => {
      const value = {};
      this.props.source.applyTableDataToRow(item, row);
      contentItems.forEach(contentItem => value[contentItem.id] = row[contentItem.metadata._propertyKey]);
      contentRows.push(value);
    });
    this.setState({
      contentItems: contentItems,
      contentRows: contentRows,
    });
  }

  private findContentItems(object: D): TableDataContentItem[] {
    let contentItems: TableDataContentItem[] = [];
    // if (!object) {
    //   return contentItems;
    // }
    if (object instanceof Array) {
      const array: any[] = object;
      array.forEach(item => contentItems.push(...this.findContentItems(item)));
    } else {
      for (let propertyKey of Object.getOwnPropertyNames(object)) {
        //@ts-ignore
        let metadata: TableDataMetadata = object[tableDataMetadataKey(propertyKey)];
        if (!metadata) {
          continue;
        }
        contentItems.push({id: uuid(), target: object, metadata: metadata});
      }
    }
    return contentItems;
  }

  render() {
    return <Box flexDirection='column'
                style={{
                  padding: PD_SM,
                  listStyleType: "none",
                }}>
      <Card>
        <Table>
          <TableHead>
            <TableRow>
              {this.state.contentItems.map(value => <TableCell><b>{value.metadata.name}</b></TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.contentRows?.map((row, index) => <DataTableRowView
              data={this.props.data[index]}
              row={row}
              contentItemsMap={this.contentItemsMap}
              disableSelection={this.props.disableSelection}
              onTableCellChanged={(data, cellId, value) => this.props.source.onTableCellChanged(data, cellId, value)}
              onTableDataSelected={data => this.props.source.onTableDataSelected(data)}/>)}
          </TableBody>
        </Table>
      </Card>
    </Box>;
  }
}
